import React, { useState } from 'react';
import axios from 'axios';
import { API } from '../../services/axiosConfig';
import swal from 'sweetalert';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () => {
    try {
      // Call your backend API to initiate the password reset
      const response = await API.post('/api/auth/forgot-password', { email: email });
      swal(response.data.message);
     
    } catch (error) {
      swal("Error", error.message,"error") 
      setMessage(error.response.data.message);
    }
  };
  
  return (
    <div>
      <h2>Forgot Password</h2>
      <div>
        <label>Email:</label>
        <input type="email" value={email} onChange={handleEmailChange} />
      </div>
      <div>
        <button onClick={handleForgotPassword}>Reset Password</button>
      </div>
  
    </div>
  );
};

export default ForgotPassword;
