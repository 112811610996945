import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { API } from '../../services/axiosConfig';
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from '@mui/icons-material';
import containersImage from "../../assets/images/ReportsEmpty.png";
import Navbar from "../../common/TopNavbar";


const OrderMatrix = ({ email }) => {
  const [orderMatrixData, setOrderMatrixData] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const navigate = useNavigate();
  const isManager = sessionStorage.getItem("isManager");
  // const email = sessionStorage.getItem('userEmail');

  useEffect(() => {

    if(isManager !== "true")
    {
      alert("Don't have rights on this module");
      navigate("/Dashboard", { replace: true });
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`${API.defaults.baseURL}order-matrix/${email}`);
        if(!response.ok)
        {
          alert("Orders Not Found");
          navigate("/Dashboard", { replace: true });
        }
        const data = await response.json();

        // const addObjectResource = Object.assign(objectOrder, data);
        setOrderMatrixData(data);
        

      } catch (error) {
        console.error('Error fetching data:', error);
        swal("Error", error?.message,"error") 
      }
    };

    fetchData();
  }, [email]);

  
  const objectOrder = {
    'Item No': null,
    'Item Name': null,
  }

  const isReadOnlyHeader = (header) => {
    const readOnlyHeaders = ["category","Region", "Inventory", "Location",'Location Name', "ShortSupply", "Total", "Item No", "Item Name","Classification","Projected Short Supply"];
    return readOnlyHeaders.includes(header);
  };

  const handleCellChange = (header, rowIndex, newValue) => {
    const updatedData = [...orderMatrixData];
    const columnName = Object.keys(updatedData[rowIndex]).find((key) => key === header);

    if (columnName) {
      updatedData[rowIndex][columnName] = newValue;
      setOrderMatrixData(updatedData);

      // Set unsavedChanges for the specific input that was changed
      setUnsavedChanges({
        ...unsavedChanges,
        [`${rowIndex}_${header}`]: true,
      });
    }
  };
  function refreshPage() {
    window.location.reload(false);
  }

  const sendUpdatedData = async (rowData, changedColumnHeader, rowIndex) => {
    try {
    
      // Extract necessary information for API call (e.g., SKU and quantity)
      const confirmation = window.confirm(`Do you want to update the quantity of ${changedColumnHeader}  to ${rowData[changedColumnHeader]}?`);

      if (!confirmation) {
        // If the user clicks "No" in the confirmation dialog, do nothing
        return;
      }
      const itemno = rowData['Item No'];
      const quantity = parseInt(rowData[changedColumnHeader]);
      const customerno = changedColumnHeader;

      if (!itemno || isNaN(quantity) || !customerno) {
        console.error('Invalid data. Please provide valid values.');
        return;
      }

      
      const endpoint = "update-quantity";
      const response = await fetch(`${API.defaults.baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quantity, customerno, itemno, email }),
      });

      if (response.ok) {
        swal("Success", "Quantity updated successfully", "success");
        window.location.reload(false);
        setUnsavedChanges({
          ...unsavedChanges,
          [`${rowIndex}_${changedColumnHeader}`]: false,
        });
        console.log('Quantity updated successfully');
      } else {
        swal("Failed", response.statusText, "error");
        console.error('Failed to update quantity:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
      swal("Error",'Error updating quantity:'+error?.message,"error") 
    }
  
  };

  return (
    <div>

              <Navbar/>
<div className="forheaderdev">
 <div className="headerdivtitle"><h5><b style={{color: "white"}}>Consolidated Order View</b></h5></div>
  
</div>
      
      <div className=" scroller" style={{margin: "10px", height: "80vh", overflowY: "auto"}}>
  <table>
          <thead style={{ position: "sticky",top: 0}}>
            <tr>
            
              <th key={"Region"} style={{textAlign: "center"}}>Region</th>
              <th key={"Location"} style={{textAlign: "center"}}>Location</th>
              <th key={"Location Name"} style={{textAlign: "center"}}>Location Name</th>
              <th key={"category"} style={{textAlign: "center"}}>Category</th>
              <th key={"Item No"} style={{textAlign: "center"}}>Item No</th>
              <th key={"Item Name"} style={{textAlign: "center", paddingLeft: "5px"}}>Item Name</th>
              <th key={"Total"} style={{textAlign: "center"}}>Total</th>
              <th key={"Inventory"} style={{textAlign: "center"}}>Inventory</th>
              <th key={"Projected Short Supply"} style={{textAlign: "center"}}>Projected Short Supply</th>

              {Object.keys(orderMatrixData[0] || {}).map((header) => (
                !isReadOnlyHeader(header)  ? (<th key={header} style={{textAlign: "center"}}>{header}</th>) : (<></>)
              ))}


            </tr>
          </thead>
          <tbody>
            {orderMatrixData.map((rowData, rowIndex) => (
              <tr key={rowIndex} style={{textAlign: "center"}}>
            
            
                {/* {Object.keys(orderMatrixData[0] || {}).map((header) => (
                 
                    isReadOnlyHeader(header)  && header ==="Item No" ? (
                      <td key={header}>
                     { rowData[header]  }
                      </td>
                    ) : ( 
                   <></>
                    )
                 
                ))} */}
                <td key="Region" > { rowData["Region"]  } </td>
                <td key="Location"  > { rowData["Location"]  } </td>
                <td key="Location Name" > { rowData["Location Name"]  } </td>
                <td key="category" > { rowData["category"]  } </td>
                <td key="Item No" className='textupper'> { rowData["Item No"]  } </td>
                <td key="Item Name" className='textleft'  style={{marginLeft: "12px", paddingLeft: "5px"}}> { rowData["Item Name"]   } </td>
                <td key="Total" > { rowData["Total"]  } </td>
          <td key="Inventory" > { rowData["Inventory"]  } </td>
          <td key="Projected Short Supply" > { rowData["Projected Short Supply"]  } </td>
          
{Object.keys(orderMatrixData[0] || {}).map((header) => (
                 
                    isReadOnlyHeader(header) ? (
                  <></>
                    ) : (
                      <td key={header} style={{textAlign: "center"}}>
        
                        <input
                          type="text" style={{textAlign: "center"}}
                          value={rowData[header] !== null ? rowData[header] : ''}
                          className={rowData[header] === null ? "hideheader" : ''}
                          onChange={(e) => handleCellChange(header, rowIndex, e.target.value)}
                        />
                        {unsavedChanges[`${rowIndex}_${header}`] && (
                          <div>                         
                             <button onClick={() => sendUpdatedData(rowData, header, rowIndex)}>Save</button>
                             <button onClick={refreshPage} >Cancel</button>
                          </div>
  
                        )}
        
                      </td>
                    )
                 
                ))}


              </tr>
            ))}







          </tbody>
        </table>
      </div>

      <Link
          to="/dashboard"
          className="btn custom-button btn-contai align-bottom mx-1"
          style={{
            top: "0px",
            position: "absolute",
            marginTop: "0px;",
            alignSelf: "left",
            color:"white"
          }}
        >
          <ArrowLeft /> Back
        </Link>
      <br/>
    </div>
  );
};

export default OrderMatrix;
