import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../../services/axiosConfig';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';

const ResetPasswordPage = () => {
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();

  // Extract token from URL on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromURL = params.get('token');
    if (tokenFromURL) {
      setToken(tokenFromURL);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {

    e.preventDefault();
    if(newPassword == confirmPassword)
      {
    try {
      const response = await API.post('/api/auth/reset-password', {
        token,
        newPassword,
      });
      swal(
        response.data.message);
  
    } catch (error) {
      swal("Error", error.message,"error")
      setMessage(error.response.data.message);
    }
    }
    else
    {
      swal("Error", "Password Mismatched, Please enter again","error")
    }

  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
      
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <button type="submit">Reset Password</button>
      </form>
   
    </div>
  );
};

export default ResetPasswordPage;
