import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { FiRefreshCcw } from "react-icons/fi";
import Sidebar from "../../common/Sidebar";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ArrowRight } from "@mui/icons-material";
import { API } from "../../services/axiosConfig";
import swal from "sweetalert";
import authService from "../../services/auth.service";
import LoadingSpinner from "../../common/LoadingSpinner";


const Dashboard = () => {
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("userRole");
  const logoLink = "https://soms.snowman.in/assets/default" + sessionStorage.getItem("groupName") + ".png";
  const isManager = sessionStorage.getItem("isManager");

  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderDatabycustomerNo, setOrderDatabycustomerNo] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [pendingApprovalOrdersAdmin, setPendingApprovalOrdersAdmin] = useState([]);
  const [pendingApprovalOrdersUser, setPendingApprovalOrdersUser] = useState([]);
  const [rejectedOrders, setRejectedOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (userRole === "ROLE_ADMIN") {
          await GetOrderData();
        }
        await GetOrderByid(userId);
      } catch (error) {
        swal("Error", error.message,"error")
        console.error("Error in useEffect:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userRole, userId]);

  useEffect(() => {
    filterOrders();
  }, [orderData, orderDatabycustomerNo]);

  const GetOrderByid = async (id) => {
    try {
      const response = await API.get("orderByUser/" + id);
      if (response.data) {
        setOrderData(response.data);
      } else {
        console.error("Invalid API response: Empty or missing data for GetOrderByid");
      }
    } catch (error) {
      swal("Error", error.message,"error") 
      console.error("Error fetching order data:", error);
    }
  };

  const GetOrderData = async () => {
    try {
      const response = await API.get("order/orderstatus/Pending for Approval");

      const response1 = await API.get("customerNoByuserId/" + userId);

      if (response.data && Array.isArray(response.data) && response1.data) {
        const customerNoList = response1.data.map(item => item.customerNo);
        console.log("Customer Number List:", customerNoList);

        const filteredData = response.data.filter(item => {
          const startIndex = item.customerNo.indexOf("(") + 1;
          const endIndex = item.customerNo.indexOf(")");
          const result = item.customerNo.slice(startIndex, endIndex);
          console.log(`Processing item: ${item.customerNo}, Extracted customerNo: ${result}`);
          return customerNoList.includes(result);
        });

        console.log("Filtered Data:", filteredData);
        setOrderDatabycustomerNo(filteredData);
      } else {
        console.error("Invalid API response: Data is missing or not in array format");
      }
    } catch (error) {
      swal("Error", error.message,"error")
      console.error("Error fetching order data:", error);
    }
  };

  const filterOrders = () => {
    setInProgressOrders(orderData.filter((order) => order.orderStatus === "Shipped"));
    setPendingOrders(orderData.filter((order) => order.orderStatus === "Pending"));
    setCompleteOrders(orderData.filter((order) => order.orderStatus === "Complete" || order.orderStatus === "Published"));
    setPendingApprovalOrdersAdmin(orderDatabycustomerNo.filter((order) => order.orderStatus === 'Pending for Approval'));
    setPendingApprovalOrdersUser(orderData.filter((order) => order.orderStatus === "Pending for Approval"));
    setRejectedOrders(orderData.filter((order) => order.orderStatus === "Rejected"));
  };
  return (

    <div style={{overflow:"scroll", height: "100vh"}}>
       {isLoading == true ? <LoadingSpinner /> : null}

      <div>
        <Container className="row">
          <div className="pt-md-4 pb-md-4 pt-sm-0 pt-sm-0">
            {/* <h4 className="h4-heading" style={{ fontWeight: "900", color: "black", marginLeft: "10px", fontSize: "30px", fontFamily: "Calibri" }}>SNOWMAN ORDER MANAGEMENT SYSTEM</h4> */}
            <img  src={logoLink} width="900px" height="100px"></img>  
          </div>

          <div class="row">
            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-pink order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Pending Orders</h7>

                  <Link
                    to='/orderlist' style={{ textDecoration: "none" }}
                  >  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Pending")}>
                      Click here<ArrowRight /> <span></span><span class="f-right text-white">{pendingOrders.length}</span>
                    </p></Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-xl-3"> 
              <div class="card card-dash bg-c-pink order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Orders to be Approved</h7>

                  <Link to='/orderlist' style={{ textDecoration: "none" }}>  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Pending for Approval")}>
                    Click here <ArrowRight /><span></span><span class="f-right text-white"> 
                    
                    {userRole === "ROLE_ADMIN"?pendingApprovalOrdersAdmin.length:pendingApprovalOrdersUser.length}
                    </span>
                  </p></Link>
                </div>
              </div>
            </div>


            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-yellow order-card">
                <div class="card-block">
                  <h7 class="m-b-20">Completed/Published Orders</h7>

                  <Link to='/orderlist' style={{ textDecoration: "none" }}>  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Complete")}>
                    Click here <ArrowRight /><span></span><span class="f-right text-white">{completeOrders.length}</span>
                  </p></Link>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-blue  order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Shipped Orders</h7>

                  <Link
                    to='/orderlist' style={{ textDecoration: "none" }}
                  >  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Shipped")}>
                      Click here<ArrowRight /> <span></span><span class="f-right text-white">{inProgressOrders.length}</span>
                    </p></Link>
                </div>
              </div>
            </div>



          </div>

          <div class="row">
            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-purple order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Create New Order</h7>

                  <Link to='/createOrder' style={{ textDecoration: "none" }}><p class="m-b-0 text-white">
                    Click here<ArrowRight /><span class="f-right text-white"></span>
                  </p></Link>
                </div>
              </div>
            </div>



            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-green order-card">
                <div class="card-block">
                  <h7 class="m-b-20">Order Template</h7>

                  <Link to='/template' style={{ textDecoration: "none" }}><p class="m-b-0 text-white">
                    Click here<ArrowRight /><span class="f-right text-white"></span>
                  </p></Link>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-red order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Rejected/Cancelled Orders</h7>
                  <Link to='/orderlist' style={{ textDecoration: "none" }}>  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Rejected")}>
                    Click here <ArrowRight /><span></span><span class="f-right text-white">{rejectedOrders.length}</span>
                  </p></Link>
                </div>
              </div>
            </div>

            {/* <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-purple order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Reports</h7>
                 <a href="https://app.powerbi.com/view?r=eyJrIjoiNjBjODY0N2YtNmI2MC00ZDI5LThjZGEtYzUzMGFlNjc3YmVlIiwidCI6IjRiZjdiMTg0LTdiOGEtNDM5MC04ZDBlLTFjMDM4M2U1N2I5NiJ9"  target="_blank" rel="noopener noreferrer"><p class="m-b-0 text-white">Click Here<ArrowRight /><span></span><span class="f-right text-purple">.</span></p></a>

                </div>
              </div>
            </div> */}
<div class="col-md-3 col-xl-3">
  <div class="card card-dash bg-c-blue order-card">
    <div class="card-block">
      <h7 class="m-b-20 text-white">Reports</h7>

      {/* <Link to='/ReportsLink' style={{ textDecoration: "none" }}>
        <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Rejected")}>
          Click here <ArrowRight /><span></span><span class="f-right text-white"></span>
        </p>
      </Link> */}
                <Link to='/reportslink' style={{ textDecoration: "none" }}><p class="m-b-0 text-white">
                    Click here<ArrowRight /><span class="f-right text-white"></span>
                  </p></Link>
    </div>
  </div>
</div>
{isManager === "true" ? 
            <div class="col-md-3 col-xl-3">
              <div class="card card-dash bg-c-blue order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Consolidated Order View</h7>

                  <Link to='/OrderMatrixTable' style={{ textDecoration: "none" }}>  <p class="m-b-0 text-white" onClick={() => sessionStorage.setItem('orderStatusList', "Rejected")}>
                    Click here <ArrowRight /><span></span><span class="f-right text-white"></span>
                  </p></Link>
                </div>
              </div>
            </div>
            : <></>}
            
{/* {isManager === true ? (
            ) : (<></>)
} */}

            {/* <div class="col-md-4 col-xl-3">
              <div class="card card-dash bg-c-naviBlue order-card">
                <div class="card-block">
                  <h7 class="m-b-20 text-white">Export Excel</h7>
                 
               <p class="m-b-0 text-white" onClick={exportToExcel}>
                  Click here<ArrowRight/><span class="f-right text-white"></span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <div className="tab-content">

        <div className="tab-pane">
          <h2>Total ID Count</h2>

        </div>

      </div>
    </div>
  );
};

export default Dashboard;
