import React, { useEffect, useState } from 'react';
import OrderMatrix from './OrderMatrix';


const OrderMatrixTable = () => {

    return (
      
        <div>
          {/* Replace 'yerampradnya@gmail.com' with the actual email you want to query */}
          <OrderMatrix email={sessionStorage.getItem('userEmail')} />
        </div>
      );
};

export default OrderMatrixTable;
