import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { API } from '../services/axiosConfig';
import { ArrowLeft } from '@mui/icons-material';
import { ArrowRight } from "@mui/icons-material";
import containersImage from "../assets/images/ReportsEmpty.png";
import Navbar from "../common/TopNavbar";

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import swal from 'sweetalert';


function ReportsDashboard() 
{

  const [DataForReport, setDataForReport] = useState([]);
  // const [DataForReport, setDataForReport] = useState({
  //   labels: DataForReport.map((data) => data.location),
  //   datasets: [{
  //   label: "Quantity",
  //   data: DataForReport.map((data) => data.orderedquantity),
  //       },],
    
  //   });

    const navigate = useNavigate();
    const fetchData = async () => {
        try {
       
          const response = await fetch(`${API.defaults.baseURL}graphqty/` + sessionStorage.getItem('userEmail'));
          if (!response.ok) {
            alert('Orders Not Found');
    
            navigate('/Dashboard', { replace: true });
            return;
          }
      
          const data = await response.json();
          console.log('Test',data[0]);
          setDataForReport(data);
          console.log('Fetched Data Reports Dashboard:', data);
      
          let filteredData = data;
      
         
        } catch (error) {
          console.error('Error fetching data:', error);
          swal("Error", error?.message,"error") 
        }
      };


      fetchData();

    return (
        <div>
<div style={{width: "700px"}} >
{/* <Chart 
  type='line'
  data={{
    labels: ['Jun', 'Jul', 'Aug'],
    datasets: [
      {
        id: 1,
        label: '',
        data: [5, 6, 7],
      },
      {
        id: 2,
        label: '',
        data: [3, 2, 1],
      },
    ],
  }}/> */}


  
</div>

<div class="row d-flex align-items-center justify-content-center" style={{marginTop:"20px",textAlign:"center",padding: "10px"}}>
      <div class="col-md-5" style={{border: "1px solid",padding: "10px",boxShadow: '5px 10px 18px #888888',margin:"10px"}}>
                  {/* <div class="paintsiconcontainer"><img src="~/Images/halo.png" class="paintsiconwidth" /></div> */}
                  <h4><b>Orders and Shipment</b></h4>
              
                  <Chart 
        type='line'
        data={{
          labels: DataForReport.map((data) => data.orderdate),
          datasets: [
            {
              id: 1,
              label: 'Orders Created',
              data: DataForReport.map((data) => data.created),
             backgroundColor : ["red"]
            },{
              id: 1,
              label: 'Orders Shipped',
              data: DataForReport.map((data) => data.shipped),
             backgroundColor : ["blue"]
            }
          ],
        }}/>
              </div>
              <div class="col-md-1">

               </div>


</div>


        </div>
    );
}

export default ReportsDashboard;
