import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AutorenewIcon from '@mui/icons-material/Publish';
import PendingIcon from '@mui/icons-material/PendingActions';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import authService from "../services/auth.service";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Collapse from "@mui/material/Collapse";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { hasChildren } from "../component/Dashboard/Utils";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SupportIcon from "@mui/icons-material/Support";
import Tooltip from "@mui/material/Tooltip";
import { FiLogOut } from "react-icons/fi";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PublishedWithChangesIcon from '@mui/icons-material/LocalShipping';
import OrdersForApproval from '@mui/icons-material/Checklist';
import ReportIcon from "@mui/icons-material/Report";
import SchoolIcon from "@mui/icons-material/School";
import PaymentsIcon from "@mui/icons-material/Payments";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import swal from "sweetalert";
import axios from "axios";
import {makeStyles } from '@material-ui/core';
import logo from "../assets/images/Snowman_Logo.png";
import { BsPersonFill } from "react-icons/bs";
import { CiLogin } from "react-icons/ci";
import { Cancel } from "@mui/icons-material";
// const username = sessionStorage.getItem('username');

const useStyles = makeStyles((theme) => ({
  dot: {
    backgroundColor: 'white',
    fontSize:'100%'
  },
}));

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  [theme.breakpoints.down("sm")]: {
    width: 0,
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  overflow: "auto",
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundImage:
    "url(https://img.freepik.com/free-photo/abstract-dark-blurred-background-smooth-gradient-texture-color-shiny-bright-website-pattern-banner-header-sidebar-graphic-art-image_1258-82968.jpg?size=626&ext=jpg&uid=R46475531&ga=GA1.2.742989516.1658734392)",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = () => {
  

  // notification state

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = useState(false);
  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [Access, setAccess] = useState([]);
  const history = useNavigate();
  const [counter, setCounter] = useState(0);
  const classes = useStyles();
  const [allNotification, setAllNotification] = useState([]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(authService.getCurrentUser());
  const [username, setUsername] = useState(""); // Initialize username state

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    sessionStorage.setItem('orderStatusList', 'Pending');
  };
  const handleClick1 = () => {
    sessionStorage.setItem('orderStatusList', 'Shipped');
  };
  useEffect(() => {

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    if (userData) {
      const username = userData.username;
      console.log(username)
      setUsername(username);
    }
  }, [isLoggedIn]); 
  const handleClick2 = () => {
    sessionStorage.setItem('orderStatusList', 'Complete');
  };
  const handleClick3 = () => {
    sessionStorage.setItem('orderStatusList', 'Rejected');
  };
  const handleClick4 = () => {
    sessionStorage.setItem('orderStatusList', 'Pending for Approval');
  };
  const items = [
    {
      icon: <PendingIcon className="sd-icons"onClick={handleClick}  />,
      title: "Pending Orders",
      to: "/orderlist",
      
    },
    {
      icon: <OrdersForApproval className="sd-icons" onClick={handleClick4} />,
      title: "Orders to be Approved",
      to: "/orderlist"
    }
    ,
    
    {
      icon: <AutorenewIcon className="sd-icons" onClick={handleClick2} />,
      title: "Completed orders",
      to: "/orderlist"
    },
    {
      icon: <PublishedWithChangesIcon className="sd-icons" onClick={handleClick1} />,
      title: "Shipped Orders",
      to: "/orderlist"
    },
    {
      icon: <Cancel className="sd-icons" onClick={handleClick3} />,
      title: "Rejected orders",
      to: "/orderlist"
    },
    // {
    //   icon: <ReceiptIcon className="sd-icons" />,
    //   title: "Lorem Ipsum",
    //   to: "/lorem",
    // },
    // {
    //   icon: <ReportIcon className="sd-icons" />,
    //   title: "Setting",
    //   to: "/lorem",
    // },
  ];

  const SingleLevel = ({ item }) => {
    return (
      <ListItem component={Link} to={item.to} button>
        <Tooltip title={item.title}>
          <ListItemIcon>{item.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText className="sidemenu-text" primary={item.title} />
      </ListItem>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const SingleLevel = ({ item }) => {
      return (
        <ListItem component={Link} to={item.to} button>
          <Tooltip title={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText className="sidemenu-text" primary={item.title} />
        </ListItem>
      );
    };

   

    const MultiLevel = ({ item }) => {
      const { items: children } = item;
      const [open, setOpen] = useState(false);

      const handleClick = () => {
        setOpen((prev) => !prev);
      };

      return (
        <React.Fragment>
          <ListItem button onClick={handleClick}>
            <Tooltip title={item.title}>
              <ListItemIcon>{item.icon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={item.title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((child, key) => (
                <MenuItem
                  component={Link}
                  to={child.to}
                  key={key}
                  item={child}
                />
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    };

    const MenuItem = ({ item }) => {
      const Component = hasChildren(item) ? MultiLevel : SingleLevel;
      return <Component item={item} />;
    };
 

    // http://localhost:10050/exam-duty?fetch_type=all_faculty_child_data

    // (function () {
    //   setTimeout(() => {
    //     axios({
    //       method: "GET",
    //       url: "/exam-duty",
    //       params: {
    //         fetch_type: "all_faculty_child_data"
    //       }
    //     }).then((res) => {
    //       // debugger
    //       let a = res?.data?.data
    //       // setNotificationData(a);
    //       const b = a.filter((data1) => data1?.Admin_Notification == "true")
    //       setAllNotification(b)
    //       console.log(a, "bbbbb");
    //       setCounter(b.length)
    //       console.log(res, "notification");

    //     });
    //   }, 600000)
    // })();

    // if (!user.access_token && user.isLoggedIn !== true)
    //   return <Navigate replace to="/" />;

    return (
      <React.Fragment>
        <ListItem button onClick={handleClick}>
          <Tooltip title={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem component={Link} to={child.to} key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };
  const handleLogout = () => {
    setTimeout(() => {
    //   dispatch(logout());
    }, 1000);
  };

  // if (!user.access_token && user.isLoggedIn !== true)
  //   return <Navigate replace to="/" />;

  const handleNotification = (data) => {
    if (data) {
      history("/exam-duty-list");
    }
  };

  // const listItemStyle = {
  //   backgroundColor: '#FFD3A3', // Replace with your desired background color
  //   color: 'black', // Replace with your desired text color
  // };

  // const listItemStyleYellow = {
  //   backgroundColor: '#FCFFB2', // Replace with your desired background color
  //   color: 'black', // Replace with your desired text color
  // };
  const listItemStyleBlue = {
    backgroundColor: "#CDF0EA", // Replace with your desired background color
    color: "black", // Replace with your desired text color
  };

  const handleAnchorClick=async()=>{
    //  const iflogout= await swal({
    //     title: "Are you sure?",
    //     text: "You want to logout this account",
    //     icon: "warning",
    //     dangerMode: true,
    //   })
    swal({
      title: "Are you sure?",
      text: "You want to logout your account ?",
      icon: "warning",
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
           swal("Logout!", "Logout Successfully", "success");
           history("/", { replace: true });
     
      }
    });
    }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "#38296c" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
                ...(open && { display: "none" }),
              }}
            >

              <MenuIcon />
              <a className="navbar-brand" href="#" style={{backgroundColor:"#fff",marginLeft:'15px',borderRadius:"30px"}}>
          <img
            src={logo}
            width="70"
            height="30"
            className="d-inline-block align-top mx-2"
          />
        </a>
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
             
             <div className="logout my-lg-0 mx-3">
                <span className="mx-2">
                    <BsPersonFill color="white" size={20} className="person"/><span className="m-1" style={{ color: 'white', fontFamily: 'math',
    fontSize: "20px"}}>{username}</span></span>
                    <Link to='' onClick={handleAnchorClick} style={{ color: 'white !important' }}><span><CiLogin fontSize="30px" color="red" style={{color:"#fff"}} /> </span></Link>
                </div>
            </Typography>
          </Toolbar>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, px: 1, pt: -2 }}>
          <DrawerHeader />
          {/* dashboard ui */}

          <Drawer variant="permanent" open={open} sx={{ overflowY: "auto" }}>
            <DrawerHeader>
            <img
                src={logo}
                width="80"
                height="30"
                className="d-inline-block align-top mx-2 align-item-center"
                style={{transform:"translate(-50%,0)"}}
            />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            {/* <Divider /> */}

            {items.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default SideNav;
