import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Snowman_Logo.png";
import "../../assets/css/emailconfi.css";
import axios from "axios";

export default function EmailConfirmation() {
  // const handleVerifyEmail = () => {
  //   var sendemail = {
  //     username: "username",
  //     email: "email@gmail.com",
  //     password:"123456",
  //     confirmpassword:"123456",
  //     role:["user"]
      
  //   };
  //   axios.put("http://localhost:5052/api/auth/EmailStatusUpdate/"+"4",sendemail).then((res) => {
  //     alert(res.data);
  // }
  //   )
 
  //   // emailConfirmationStatus:true
  //   // Handle the click event to verify the email address
  // };
  return (
    <>
      <div className="email">
        <div className="container-email">
          <div className="image">
            {/* <img src={logo} alt="Company Logo" class="logo-image" /> */}
          </div>
          <div className="color"></div>
        </div>
        <div className="content">
          <div className="overlay">
            <div style={{ textAlign: "center" }}>
              <div className="header mt-5">
                <img
                  src={"assets/email.png"}
                  alt="Email Box"
                  style={{ width: "50px" }}
                />
                <h2>Confirm Email</h2>
              </div>
              <div className="separator mb-5"></div>
              <div className="footer">
                <p className="pb-3">
                  Thank you for your support.<br /> We have successfully verified your
                  email address.<br /> you can now proceed to your Login page{" "}
                </p>
                <Link className="btn btn-primary"  to="/">
                  TAKE ME LOGIN
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
