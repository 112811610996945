import React, { useEffect, useState } from "react";
import "../../assets/css/OrderList.css";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import Navbar from "../../common/TopNavbar";
import { useLocation } from "react-router-dom";
import { ArrowLeft, Margin } from "@mui/icons-material";
import { API } from "../../services/axiosConfig";
import LoadingSpinner from "../../common/LoadingSpinner";
import Modal from "@material-ui/core/Modal";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { BiSearch } from 'react-icons/bi'
import { ImCross } from 'react-icons/im'
import { FaFilter } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500, // Set your desired height
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const headers = [
  {
    ITEM_NO: "ITEM NO",
    PACKING_DESCRIPTION: "PACKING DESCRIPTION",
    QUANTITY: "QUANTITY",
    LOT_SIZE: "LOT SIZE",
  },
];

function OrderListPage() {
  const location = useLocation();
  const state = location.state;
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [orderData1, setOrderData1] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderFitlerData, setOrderFilterData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [options, setOptions] = useState([]);
  const orderStatusList = sessionStorage.getItem("orderStatusList");
  const [rejectReason, setRejectReason] = useState('Select reason');
  const orderStatusCompletePublished = sessionStorage.getItem("orderStatusCompletePublished");
  const userId = sessionStorage.getItem("userId");
  const aproval = sessionStorage.getItem("aproval");
  const userRole = sessionStorage.getItem("userRole");
  const userEmail = sessionStorage.getItem("userEmail");
  const [orderId2, setOrderId2] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [cancel, setCancel] = React.useState(false);

  const today = new Date();
  const date = Moment(today).format("YYYY-MM-DD");

  useEffect(() => {
    try {
      if (userRole === "ROLE_ADMIN") {
        if (orderStatusList === "Pending for Approval") {
          GetOrderData();
        } else {
          GetOrderByid(userId);
        }
      } else if (userRole === "ROLE_USER") {
        GetOrderByid(userId);
      } else {
        console.warn("Invalid user role:", userRole);
      }
      getStoreData();
      getTemplates();
    } catch (error) {
      console.error("Error in useEffect:", error);
      swal("Error", error?.message,"error") 
    }
  }, []);

  const GetOrderData = async () => {

    try {
      setIsLoading(true);

      // Assuming your API call here
      // const response = await API.get("order/orderstatus/"+orderStatusList);

      // // Filter orders based on orderStatusList
      // const Data1 = response.data.filter(
      //   (order) => order.orderStatus === orderStatusList
      // );
      // const Data1 = response.data.filter((order) => {
      //   if (orderStatusList === "Complete") {
      //     // If orderStatusList is "Complete", filter data with orderStatus "Complete" or "Published"
      //     return order.orderStatus === "Complete" || order.orderStatus === "Published";
      //   } else {
      //     // Otherwise, filter data based on the provided orderStatusList
      //     return order.orderStatus === orderStatusList;
      //   }
      // });
      let Data1;
   if (orderStatusList === "Complete") {
    const responseComplete = await API.get("order/orderstatus/Complete");
    const responsePublished = await API.get("prder/orderstatus/Published");
    console.log(responseComplete)
    console.log(responsePublished)

    if (Object.keys(responseComplete.data).length === 0) {
      // If responseComplete is empty, use responsePublished
      Data1 = responsePublished;
  }
  else if (Object.keys(responsePublished.data).length === 0) {
    // If responseComplete is empty, use responsePublished
    Data1 = responseComplete;
} else {
      // If responseComplete is not empty, merge both responses
      Data1 = {
          ...responseComplete,
          ...responsePublished
      };
  }
}else{
  Data1 = await API.get("order/orderstatus/"+orderStatusList);

}

      // If startDate and endDate are selected, apply date filtering
      let filteredData = [...Data1.data];
      if (startDate && endDate) {
        filteredData = Data1.data.filter(
          (order) => {
            const orderDate = new Date(order.orderPlacedDate);
            return orderDate >= startDate && orderDate <= new Date(endDate.getTime() + 86400000); // Adding one day to include the end date
          }
        );

      } 
      
      const response1 = await API.get("customerNoByuserId/" + userId);
     
      const customerNoList = response1.data.map(item => item.customerNo);


      const customerData =Data1.data.filter(item => {
        const startIndex = item.customerNo.indexOf("(") + 1;
        const endIndex = item.customerNo.indexOf(")");

        const result = item.customerNo.slice(startIndex, endIndex);
        const isCustomerInList = customerNoList.includes(result);
        const isPendingForApproval = item.orderStatus === "Pending for Approval";

        return isCustomerInList && isPendingForApproval;

        /// return customerNoList.includes(resul/order.t)
      });
      console.log(customerData)
      // Sort orders by orderPlacedDate in descending order
      const sortedOrders = filteredData.sort((a, b) => {
        return new Date(b.orderPlacedDate) - new Date(a.orderPlacedDate);
      });
      setOrderData1(customerData);

      // setOrderData1(sortedOrders);
      setOrderFilterData(Data1.data);
   
    } catch (error) {
      console.error('Error fetching order data:', error);
      swal("Error", error?.message,"error") 
    }
    setIsLoading(false);
  };


  const GetOrderByid = async (id) => {
try {
      setIsLoading(true);
      // const Data1 = response.data.filter((order) => {
      //   if (orderStatusList === "Complete") {
      //     // If orderStatusList is "Complete", filter data with orderStatus "Complete" or "Published"
      //     return order.orderStatus === "Complete" || order.orderStatus === "Published";
      //   } else {
      //     // Otherwise, filter data based on the provided orderStatusList
      //     return order.orderStatus === orderStatusList;
      //   }
      // });
let response;
   if (orderStatusList === "Complete") {
    const responseComplete = await API.get("orderstatus/Complete/id/" +id);
    const responsePublished = await API.get("orderstatus/Published/id/" +id);
  
  if (Object.keys(responseComplete.data).length === 0) {
    // If responseComplete is empty, use responsePublished
    response = responsePublished;
}
else if (Object.keys(responsePublished.data).length === 0) {
  // If responseComplete is empty, use responsePublished
  response = responseComplete;
} else {
    // If responseComplete is not empty, merge both responses
    const combinedData = [
      ...responseComplete.data,
      ...responsePublished.data
  ];

  response = {
      ...responseComplete,  // includes status, headers, etc.
      data: combinedData   // merge the data arrays
  };
  }
}else{
   response = await API.get("orderstatus/"+orderStatusList+"/id/" +id);

}

      // If startDate and endDate are selected, apply date filtering
      let filteredData = [...response.data];
      if (startDate && endDate) {
        filteredData = response.data.filter(
          (order) => {
            const orderDate = new Date(order.orderPlacedDate);
            return orderDate >= startDate && orderDate <= new Date(endDate.getTime() + 86400000); // Adding one day to include the end date
          }
        );
      }
      // Sort orders by orderPlacedDate in descending order
      const sortedOrders = filteredData.sort((a, b) => {
        return new Date(b.orderPlacedDate) - new Date(a.orderPlacedDate);
      });

      setOrderData1(filteredData);
   
      // // Assuming the API response contains the order data
      // if (response.data) {
      //   setOrderData(response.data);
      //   const Data1 = response.data.filter(
      //     (order) => order.orderStatus === orderStatusList
      //   );


      //   setIsLoading(false);
      // } else {
      //   console.error("Invalid API response: Empty or missing data");
      // }
      setIsLoading(false);

    } catch (error) {
      console.error("Error fetching order data:", error);
      swal("Error", error?.message,"error") 
    }
  
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so we add 1.
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleSearch = () => {

    const pendingOrders = orderData1.filter(
      (item) => item.orderStatus === orderStatusList
    );
    if (searchTerm === "") {
      setOrderData1(pendingOrders);
    } else {
      const filteredData = pendingOrders.filter(
        (item) =>
          item.orderNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.customerNo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const sortedOrders = [...filteredData].sort((a, b) => {
        return new Date(b.orderPlacedDate) - new Date(a.orderPlacedDate);
      });
      setOrderData1(filteredData);
    }
  };
  const handleReset = () => {
    setSearchTerm("");
    // Reset the filteredOrderData to show all pending orders
    if (userRole === "ROLE_ADMIN") {
      if (orderStatusList === "Pending for Approval") {
        GetOrderData();
      } else {
        GetOrderByid(userId);
      }
    } else if (userRole === "ROLE_USER") {
      GetOrderByid(userId);
    }
  };
  useEffect(() => {
    if (userRole === "ROLE_ADMIN") {

      if (orderStatusList === "Pending for Approval") {
        GetOrderData();
      } else {
        GetOrderByid(userId);
      }
    } else if (userRole === "ROLE_USER") {
      GetOrderByid(userId);
    }
  }, [startDate, endDate]);

  const handleResetdate = () => {
    // Reset the date filters
    setStartDate("");
    setEndDate("");

    if (userRole === "ROLE_ADMIN") {

      if (orderStatusList === "Pending for Approval") {
        GetOrderData();
      } else {
        GetOrderByid(userId);
      }
    } else if (userRole === "ROLE_USER") {
      GetOrderByid(userId);
    }

  };

  const getStoreData = async () => {
    try {
      const response = await API.get("storename");

      // Assuming the API response is an array of option objects
      if (response.data && Array.isArray(response.data)) {
        setOptions(response.data);
      } else {
        console.error("Invalid API response format:", response.data);
      }
    } catch (error) {
      swal("Error", error.message,"error")
      console.error("Error fetching options:", error);
    }
  };

  const handleRevokeOrder = async (orderData, id) => {
    if (!orderData || !id) {
      swal("error","Invalid input data. Please provide valid order data and ID.")
    
      return;
    }

    const recorddata = {
      location: orderData.location,
      customerNo: orderData.customerNo,
      orderId: orderData.orderId,
      orderDate: date,
      orderPlacedDate: date,
      comment: orderData.comment,
      orderNo: orderData.orderNo,
      orderStatus: "Pending",
      storeName: orderData.storeName,
      userId: orderData.userId,
      orderDetailsModelClass: orderData.orderDetailsModelClass.map((item) => ({
        amount: item.amount,
        price: item.price,
        quantity: item.quantity,
        sku: item.sku,
        orderDetailId: item.orderDetailId,
        orderId: item.orderId,
        userId: item.userId,
        serialNo: item.serialNo,
        actualQuantity: item.actualQuantity,
      })),
    };
    try {
      API.put(`orders/${id}`, recorddata).then((res) => {
        swal("Reorder!", "Order Reordered successfully!", "success").then(
          (willDelete) => {
            if (willDelete) {
              GetOrderByid(userId);
            }
          }
        );
      });
    } catch (error) {
      swal("error","An error occurred while publishing the order." +  error)

      console.error("Error saving order:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCancel(false)
  };

  const handleOpen = () => {

    setOpen(true);
  };
  const handleCancelOpen = () => {

    setCancel(true);
  };

  const handleReject1 = () => {
    console.log(rejectReason);
    if (selectedOrders.length > 0 && rejectReason) {
      // Use Promise.all to send API requests for each selected order
      Promise.all(selectedOrders.map(orderId =>
        API.post(
          `/api/orders/reject?orderId=${orderId}&rejectReason=${rejectReason}`
        )
      ))
        .then((responses) => {
          // Handle success response
          swal("Update!", "Order(s) rejected successfully!", "success");
          setOpen(false);
          setCancel(false);
          navigate("/orderlist", { replace: true });
          if (userRole === "ROLE_ADMIN") {
            if (orderStatusList === "Pending for Approval") {
              GetOrderData();
            } else {
              GetOrderByid(userId);
            }
          } else if (userRole === "ROLE_USER") {
            GetOrderByid(userId);
          }
        })
        .catch((error) => {
          swal("Error", error.message,"error")
          console.error("Error:", error);
          // Handle error
        });
    } else {
      if (orderId2 && rejectReason) {
        API.post(
          `/api/orders/reject?orderId=${orderId2}&rejectReason=${rejectReason}`
        )
          .then((response) => {
            swal("Update!", "Order Reject successfully!", "success");
            setOpen(false);
            setCancel(false);
            navigate("/orderlist", { replace: true });
            if (userRole === "ROLE_ADMIN") {
              if (orderStatusList === "Pending for Approval") {
                GetOrderData();
              } else {
                GetOrderByid(userId);
              }
            } else if (userRole === "ROLE_USER") {
              GetOrderByid(userId);
            }

          })
          .catch((error) => {
            swal("Error", error.message,"error")
            console.error("Error:", error);
            // Handle error
          });
      }
    }
  };

  const handleAprove = async (orderData, orderId1) => {
    const startIndex = orderData.customerNo.indexOf("(") + 1;
    const endIndex = orderData.customerNo.indexOf(")");
    const result = orderData.customerNo.slice(startIndex, endIndex);
    const isfirstapproved = orderData.firstAppEmail;
    try {
      const res1 = await API.get("limitByUser/" + result);
      console.log(res1.data, "555");

      const updatedTotalAmount = orderData.orderDetailsModelClass.reduce(
        (acc, row) => acc + row.amount,
        0
      );
      if (res1.data[0].avalLimit < updatedTotalAmount) {
        const res2 = await API.get(`appMatrix/${result}`);
        if (!res2.data) {
          console.log(res2.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }
        const orderData = res2.data[0];
       
        // Your other conditions and API calls here
        if (orderData.firstAppEmail.indexOf(userEmail) !== -1) {
          const statusUser = "firstAppEmail";
          await AproveMethod(orderId1, statusUser);
        }
        if (orderData.secondAppEmail.indexOf(userEmail) !== -1) {
          const statusUser = "secondAppEmail";
          if(isfirstapproved == true)
            {
              await AproveMethod(orderId1, statusUser);
            }
            else
            {
              return swal('Info', "You cannot approve this order because first level approval is still pending", "info");
            }
        }

      } else {
        const res3 = await API.get(`appMatrix/${result}`);
        if (!res3.data) {
          console.log(res3.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }
        let statusUser = "";
        if (res3.data[0].firstAppEmail.indexOf(userEmail) !== -1) {
          statusUser = "firstAppEmailAprove";
          await AproveMethod(orderId1, statusUser);
        } else {
     swal("Error!", "Please note that this order is not applicable for approval!", "warning");
        }


      }
      navigate("/orderlist", { replace: true });
    } catch (error) {
      console.error("Error:", error);
      swal("Error", error?.message,"error") 
      // Handle error
    }
  };

  const AproveMethod = async (orderId1, statusUser) => {
    await API.post(
      `/api/orders/approvebyUserCreditmail?orderId=${orderId1}&statusUser=${statusUser}`
    )
      .then((response) => {

        swal("Update!", "Order Approve successfully!", "success");
        GetOrderData();
      })
      .catch((error) => {
        swal("Error", error.message,"error")
        console.error("Error:", error);
        // Handle error
      });
  };

  const handlePublishRow = async (orderData, id) => {
    if (!orderData || !id) {
      swal("error", "Invalid input data. Please provide valid order data and ID.")

      return;
    }
    const data = {
      header: {
        document_Type: "Order",
        no: orderData.orderNo,
        location_Code: orderData.location,
        order_Date: orderData.orderPlacedDate,
        document_Date: "2023-08-18T15:29:12.508Z",
        responsibility_Center: orderData.location,
        sell_to_Customer_No: orderData.customerNo,
        posting_Date: "2023-08-18T15:29:12.508Z",
      },
      lines: [
        {
          document_Type: "Order",
          document_No: "106",
          line_No: 1,
          type: "Item",
          no: "CP11017B",
          quantity: 3,
          unit_of_Measure_Code: "CASE",
          unit_of_Measure: "CASE",
          unit_Price: 10,
          line_Amount: 10,
        },
      ],
    };
    try {
      if (aproval == "true") {
       console.log(orderData.orderDetailsModelClass);
        const recorddata = {
          location: orderData.location,
          customerNo: orderData.customerNo,
          orderId: orderData.orderId,
          orderDate: orderData.orderPlacedDate,
          comment: orderData.comment,
          orderNo: orderData.orderNo,
          orderStatus: "Published",
          storeName: orderData.storeName,
          userId: orderData.userId,
          orderPlacedDate: orderData.orderPlacedDate,
          orderDetailsModelClass: orderData.orderDetailsModelClass.map(
            (item) => ({
              amount: item.amount,
              price: item.price,
              quantity: item.quantity,
              sku: item.sku,
              orderDetailId: item.orderDetailId,
              orderId: item.orderId,
              userId: item.userId,
              serialNo: item.serialNo,
              actualQuantity: item.quantity,
            })
          ),
        };
   
        try {
          API.put(`orders/${id}`, recorddata).then((res) => {
            swal("Update!", "Order Updated successfully!", "success").then(
              (willDelete) => {
                if (willDelete) {
                  GetOrderByid(userId);
                }
              }
            );
          });
        } catch (error) {
          swal("error","An error occurred while publishing the order."+error, "error")
       
          console.error("Error saving order:", error);
        }
      } else {
        const startIndex = orderData.customerNo.indexOf("(") + 1;
        const endIndex = orderData.customerNo.indexOf(")");
        const result = orderData.customerNo.slice(startIndex, endIndex);
        const response = await API.get("limitByUser/" + result);
        const LimitCheck = response.data[0].avalLimit;
        const updatedTotalAmount = orderData.orderDetailsModelClass.reduce(
          (acc, row) => acc + row.amount,
          0
        );
        console.log(updatedTotalAmount);
        if (LimitCheck < updatedTotalAmount) {
          sendEmailToUserAboveCreditLimit(result, id);
        } else {
          firstAppEmailBelowCreditLimit(result, id);
        }
        const res2 = await API.get(`appMatrix/${result}`);
        console.log(res2.data.length, "77777777777777");
        if(res2.data.length!=0){
       
        
        const firstAppEmail = res2.data[0].firstAppEmail;
        const secondAppEmail = res2.data[0].secondAppEmail;
        const thirdAppEmail = res2.data[0].thirdAppEmail;
        const recorddata = {
          location: orderData.location,
          customerNo: orderData.customerNo,
          orderId: orderData.orderId,
          orderDate: orderData.orderPlacedDate,
          orderPlacedDate: orderData.orderPlacedDate,
          comment: orderData.comment,
          orderNo: orderData.orderNo,
          orderStatus: "Pending for Approval",
          storeName: orderData.storeName,
          userId: orderData.userId,
          firstAppEmailId: firstAppEmail,
          secondAppEmailId: secondAppEmail,
          thirdAppEmailId: thirdAppEmail,
          orderDetailsModelClass: orderData.orderDetailsModelClass.map(
            (item) => ({
              amount: item.amount,
              price: item.price,
              quantity: item.quantity,
              sku: item.sku,
              orderDetailId: item.orderDetailId,
              orderId: item.orderId,
              userId: item.userId,
              serialNo: item.serialNo,
              actualQuantity: item.quantity,
            })
          ),
        };
        try {
          API.put(`orders/${id}`, recorddata).then((res) => {
            swal(
              "Approve!",
              "Pending orders for approval will be sent by email!",
              "success"
            ).then((willDelete) => {
              if (willDelete) {
                GetOrderByid(userId);
              }
            });
          });
        
        } catch (error) {
          swal("error","An error occurred while publishing the order."+error)
        
          console.error("Error saving order:", error);
         } }else{return swal('Error', "Publish order cannot proceed plz provide email", "error")}
      }
    } catch (error) {
      swal("error", "An error occurred while publishing the order."+error);
   console.error("Error saving order:", error);
    }
  };

  const firstAppEmailBelowCreditLimit = (result, id) => {
    API.get(`appMatrix/${result}`).then((res) => {
      if (!res.data) {
        console.log(res.data);
        return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
      } else {
        const appMatrixModelClass = { firstAppEmail: res.data[0].firstAppEmail };
      API.post(
        "/publishConfirmationOrderbyCustomernofirstemail",
        appMatrixModelClass,
        {
          headers: {
            orderId: id,
          },
        }
      )
       
        .catch((error) => {
          swal("Error", error.message,"error")
          console.error(error);
        });
      }
    });
  };

  const sendEmailToUserAboveCreditLimit = (result, id) => {
    API.get(`appMatrix/${result}`)
      .then((res) => {
        if (!res.data) {
          console.log(res.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        } else {
          const payload = {
            firstAppEmail: res.data[0].firstAppEmail,
            secondAppEmail: res.data[0].secondAppEmail,
            thirdAppEmail: res.data[0].thirdAppEmail,
          };
  
          API.post("/publishConfirmationOrderbyCustomerno3email", payload, {
            headers: {
              orderId: id,
            },
          })
            .then((response) => {
              // Assuming the response is JSON
              // if (!response.ok) {
              //   return response.json().then((data) => {
              //     throw new Error(data.message || "Failed to send email");
              //   });
              // }
              // return response.json();
            })
            .then((data) => {
              console.log(data); // Handle the response as needed
            //  swal("Success", "Email sent successfully.", "success");
            })
            .catch((error) => {
              swal("Error", error.message, "error");
              console.error(error);
            });
        }
      })
      .catch((error) => {
        swal("Error", error.message, "error");
        console.error(error);
      });
  };
  
  const handleRepeatRow = async (orderData) => {
    try {
      const prefix = orderData.location.substring(0, 3);
      API.post(`ordernumber/${prefix}`).then((response) => {
        console.log(response.data);
        const genratedorderNo = response.data;

        const repeatorderData = {
          location: orderData.location,
          customerNo: orderData.customerNo,
          orderPlacedDate: orderData.orderPlacedDate,
          comment: orderData.comment,
          orderNo: genratedorderNo,
          orderStatus: "Pending",
          storeName: orderData.storeName,
          userId: orderData.userId,
          orderDetailsModelClass: orderData.orderDetailsModelClass.map(
            (item) => ({
              amount: item.amount,
              price: item.price,
              quantity: item.quantity,
              sku: item.sku,
              userId: item.userId,
              serialNo: item.serialNo,
              actualQuantity: item.actualQuantity,
            })
          ),
        };
        API.post("order", repeatorderData).then((res) => {
          swal("Order Repeated!", "Order repeated successfully!", "success");
          navigate("/order/" + res.data, { replace: true });
        });
      });
    } catch (error) {
      swal("error ","An error occurred while repeating the order."+error)
    
      console.error("Error repeating order:", error);
    }
  };

  const getTemplates = async () => {
    try {
      const response = await API.get("templates");
      setTemplates(response.data); // Update the state with the fetched template data
    } catch (error) {
      // swal("Error", "Error fetching templates:"+error?.message,"error") 
      console.error("Error fetching templates:", error);
    }
  };

  const handleCheckboxChange = (orderId) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const handleRejectSelectedOrders = () => {
    setOpen(true);
  };
  const handleFilter = () => {
    if (userRole === "ROLE_ADMIN") {
      if (orderStatusList === "Pending for Approval") {
        GetOrderData();
      } else {
        GetOrderByid(userId);
      }
    } else if (userRole === "ROLE_USER") {
      GetOrderByid(userId);
    }

  };
  const handleApproveSelectedOrders = async () => {
    try {
      for (const orderId of selectedOrders) {
        const order = orderData1.find((iorder) => iorder.orderId === orderId);
        await handleAprove(order, orderId);
      }

      // Clear the selectedOrders after handling
      setSelectedOrders([]);

      // Additional logic or navigation if needed
      navigate("/orderlist", { replace: true });
    } catch (error) {
      console.error("Error handling selected orders:", error);
                              
      swal("Error", "Error handling selected orders:"+error?.message,"error")                                                                                                                                                                                                                     
      // Handle error if needed
    }
  };
  function formatAmount(amount) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  
  return (
    <>
      <div className="App order-list">
        {isLoading == true ? <LoadingSpinner /> : null}
        <Navbar /> {/* {renderTemplates()} */}
        <h4
          className="h4-heading"
          style={{
            fontWeight: "400",
            color: "black",
            marginLeft: "10px",
            fontSize: "30px",
          }}
        > </h4>

        <br />
        <Link to="/Dashboard" style={{ textDecoration: "none" }}>
          {/* <ArrowLeft /> Back */}
          <button className="btn custom-button btn-contai align-bottom mx-1"
            style={{
              top: "0px",
              position: "absolute",
              marginTop: "0px;",
              alignSelf: "left",
              color: "white"
            }}>
            <ArrowLeft /> Back
          </button>
        </Link>


        <div className="forheaderdev" >

          {/* <img  src={containersImage} alt="" style={{ maxHeight: "100%", height:"20vh", width: "100%", objectFit: "fill"}}></img> */}
          <div className="headerdivtitle" style={{ top: "-70px", position: "absolute", color: "white" }}>
            <h5>
              <b style={{ color: "white" }}>{orderStatusList + " Orders"}</b>
            </h5>
          </div>
        </div>


        <div className="form-group row order-list-form">
          <div className="d-flex">
            <div className="row col-md-6" style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}>
              <div className="d-flex">
                <div style={{ marginBottom: "10px", marginRight: "3px", marginLeft: "10px" }} className="">
                  {/* <label htmlFor="startDate" style={{marginRight:"5px"}}>Start Date :</label> */}
                  <label htmlFor="endDate" style={{ marginRight: "10px", marginTop: "10px", marginLeft: "25px" }} > Filter By Orderdate</label>
                  <DatePicker
                    id="startDate"
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <label htmlFor="endDate" style={{ marginRight: "10px", marginTop: "10px", marginLeft: "none" }} >To</label>

                <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                  {/* <label htmlFor="endDate" style={{marginRight:"5px"}} >End Date :</label> */}
                  <DatePicker
                    id="endDate"
                    className="form-control"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select end date"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="col-sm-12 col-md-1">
                  {" "}
                  <button
                    className="form-control custom-btn btn-outline-dark btn-sm"
                    onClick={handleFilter}
                    style={{ minWidth: "70px", padding: "6px" }}
                    title="Filter Orders"
                  >
                    {/* Filter Orders */}
                    <FaFilter size={20} />
                  </button>
                </div>
                <div
                  className="col-sm-12 col-md-1"
                  style={{ marginLeft: "20px" }}
                >
                  <button className="form-control custom-btn btn-outline-light btn-sm"
                    type="button"
                    style={{ minWidth: "70px", padding: "6px" }}
                    onClick={handleResetdate}
                    title="Reset"
                  >
                    {" "}
                    {/* Reset */}
                    <i><ImCross size={20} /></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="row col-md-6" style={{ marginLeft: "20px" }}>
              <div className="col-sm-12 col-md-6">
                <input
                  style={{ marginLeft: "20PX" }}
                  type="text"
                  className="form-control"
                  placeholder="Search by Order No or Location"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div
                className="col-sm-12 col-md-1"
                style={{ marginLeft: "20px" }}
              >
                <button
                  className="form-control custom-btn btn-outline-dark btn-sm "
                  title="Search"
                  style={{ minWidth: "70px", padding: "6px" }}
                  type="button"
                  onClick={handleSearch}
                >
                  {" "}
                  {/* Search */}
                  <BiSearch size={20} />
                </button>
              </div>
              <div
                className="col-sm-12 col-md-1"
                style={{ marginLeft: "20px" }}
              >
                <button
                  className="form-control custom-btn btn-outline-light btn-sm"
                  type="button"
                  style={{ minWidth: "70px", padding: "6px" }}
                  onClick={handleReset}
                  title="Reset"

                >
                  {" "}
                  <i><ImCross size={20} /></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "30px" }}>
            <div className="col-sm-12 col-md-4" >
              {orderStatusList === "Pending for Approval" &&
                userRole === "ROLE_ADMIN" ? (

                <div>
                  <button
                    className="btn btn-sm btn-outline-success custom-btn-approve-selected"
                    disabled={selectedOrders.length === 0}
                    onClick={handleApproveSelectedOrders}
                  >
                    Approve Selected Orders
                  </button>

                  <button
                    className="btn btn-sm btn-outline-success custom-btn-approve-selected"
                    disabled={selectedOrders.length === 0}
                    onClick={handleRejectSelectedOrders}
                  >
                    Reject Selected Orders
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <p>
            <div
              className="col-md-12 order-list-table"
              style={{ overflowX: "auto", marginLeft: "10px", padding: "10px" }}
            >
              <table className="table table-bordered tb" style={{ Margin: "15px" }}>
                <thead style={{ textAlign: 'center' }} >
                  {orderStatusList === "Pending for Approval" &&
                    userRole === "ROLE_ADMIN" ? (
                    <th className="tbleheding center p-3">Select</th>
                  ) : <></>}
                  <th className="tbleheding center p-3">Order No</th>
                  <th className="tbleheding p-3">Customer</th>

                  {orderStatusList === "Complete" && (
                    <th className="tbleheding p-3">Sales Order No</th>
                  )}
                  {orderStatusList === "Shipped" && (
                    <th className="tbleheding p-3">Shipment Number</th>
                  )}
                  <th className="tbleheding p-3">Order Date</th>
                  <th className="tbleheding p-3">Location</th>
                  <th className="tbleheding p-3">Order Quantity</th>
                  {orderStatusList === "Complete" && (
                    <th className="tbleheding p-3">Final Order Quantity</th>
                  )}
                  {orderStatusList === "Shipped" && (
                    <th className="tbleheding p-3">Shipped Quantity</th>
                  )}
                  {orderStatusList === "Shipped" && (
                    <th className="tbleheding p-3">Fill Rate %</th>
                  )}
                  {orderStatusList === "Shipped" ? (
                    <th className="tbleheding p-3">Shipped Order Value</th>
                  ) : (
                    <th className="tbleheding p-3">Order Value</th>
                  )}

                  {/* <th className="tbleheding p-3">Shipment Date</th>
                  <th className="tbleheding p-3">Sales Order Date</th> */}

                  {orderStatusList === "Pending for Approval" && (
                    <th className="tbleheding p-3">Order Approval Status</th>
                  )}
                  {orderStatusList === "Rejected" && (
                    <th className="tbleheding p-3">Reason for Rejection</th>
                  )}

                  <th className="tbleheding p-3" colSpan="2">
                    Action
                  </th>
                </thead>
                <tbody>
                  {orderData1.length > 0 ? (
                    orderData1.map((iorder, index) => (
                      <tr key={iorder.orderId}>
                        {userRole === "ROLE_ADMIN" && orderStatusList === "Pending for Approval" ?
                          (iorder.firstAppEmailId === userEmail || iorder.secondAppEmailId === userEmail )?
                            (iorder.firstAppEmail == true  &&  iorder.secondAppEmail == true ) ||((iorder.firstAppEmailId === userEmail && iorder.firstAppEmail == true) ||
                            (iorder.secondAppEmailId === userEmail && iorder.secondAppEmail == true)  ) ?
                              <td>
                                <input
                                  type="checkbox"
                                  disabled
                                  style={{ width: '20px', height: '20px' }}
                                />
                              </td> : <td>
                                <input
                                  type="checkbox"
                                  checked={selectedOrders.includes(iorder.orderId)}
                                  onChange={() =>
                                    handleCheckboxChange(iorder.orderId)
                                  }
                                  style={{ width: '20px', height: '20px' }}
                                />
                              </td> : <td></td> : <></>}


                        <td label="OrderNo">
                          <Link to={`/order/${iorder.orderId}`}>
                            {iorder.orderNo}
                          </Link>{" "}
                        </td>
                        <td label="CustomerNo">{iorder.customerNo}</td>
                        {(iorder.orderStatus === "Published" || iorder.orderStatus === "Complete") && (
                          <td style={{ textAlign: 'center' }} label="SoNumber">{iorder.soNumber}</td>
                        )}

                        {iorder.orderStatus == "Shipped" && (
                          <td style={{ textAlign: 'center' }} label="SoNumber">{iorder.shipmentNumber}</td>
                        )}
                        <td style={{ textAlign: 'center' }} label="OrderDate">{formatDate(iorder.orderPlacedDate)}</td>
                        <td style={{ textAlign: 'center' }} label="Location"> {iorder.location}</td>

                        {(iorder.orderStatus === "Published" || iorder.orderStatus === "Complete")  && (
                        <td label="Qty" style={{ textAlign: 'right' }}>
                          {iorder.orderDetailsModelClass.length > 0
                            ? iorder.orderDetailsModelClass.reduce(
                              (totalQty, orderDetail) =>
                                totalQty +
                                (orderDetail.actualQuantity > 0
                                  ? orderDetail.actualQuantity
                                  : orderDetail.quantity),
                              0
                            )
                            : 1}
                        </td>
                        )}

                        
                       {(iorder.orderStatus !== "Published" && iorder.orderStatus !== "Complete")  && (
                        <td label="Qty" style={{ textAlign: 'right' }}>
                          {iorder.orderDetailsModelClass.length > 0
                            ? iorder.orderDetailsModelClass.reduce(
                              (totalQty, orderDetail) =>
                                totalQty +
                                (orderDetail.quantity > 0
                                  ? orderDetail.quantity
                                  : 1),
                              0
                            )
                            : 1}
                        </td>
                        )}

                        {(iorder.orderStatus === "Published" || iorder.orderStatus === "Complete") && (
                          <td style={{ textAlign: 'right' }} label="Final Order Quantity">{iorder.orderDetailsModelClass.length > 0
                            ? iorder.orderDetailsModelClass.reduce(
                              (totalActQty, orderDetail) =>
                                totalActQty +
                                (orderDetail.quantity > 0
                                  ? orderDetail.quantity
                                  : 1),
                              0
                            )
                            : 1}</td>
                        )}
                        {iorder.orderStatus === "Shipped" && (
                          <td style={{ textAlign: 'right' }} label="Qty Delivered">
                            {iorder.orderDetailsModelClass.length > 0
                              ? parseFloat(
                                iorder.orderDetailsModelClass.reduce(
                                  (totalAmount, orderDetail) =>
                                    parseFloat(
                                      parseFloat(totalAmount) +
                                      parseFloat(orderDetail.qtyDelivered)
                                    ).toFixed(2),
                                  0
                                )
                              )
                              : 0}
                          </td>
                        )}
                        {iorder.orderStatus === "Shipped" && (
                          <td style={{ textAlign: 'right' }}  label="Fill Rate %">
                            {iorder.orderDetailsModelClass.length > 0
                              ? (
                                (parseFloat(
                                  iorder.orderDetailsModelClass.reduce(
                                    (totalAmount, orderDetail) =>
                                      parseFloat(
                                        parseFloat(totalAmount) +
                                        parseFloat(orderDetail.qtyDelivered)
                                      ).toFixed(2),
                                    0
                                  )
                                ) *
                                  100) /
                                iorder.orderDetailsModelClass.reduce(
                                  (totalQty, orderDetail) =>
                                    totalQty +
                                    (orderDetail.quantity > 0
                                      ? orderDetail.quantity
                                      : 1),
                                  0
                                )
                              ).toFixed(2)
                              : 0}
                          </td>
                        )}

<td style={{ textAlign: 'right' }}>
  {iorder.orderDetailsModelClass.length > 0
    ? iorder.orderDetailsModelClass.reduce(
        (totalAmount, orderDetail) =>
          totalAmount + parseFloat(orderDetail.amount),
        0
      ).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' /-'
    : 0}
</td>
{iorder.orderStatus === "Pending for Approval" && (
                          <td style={{ textAlign: 'center' }} label="Status">
                            First :{" "}
                            <b>
                              {iorder.firstAppEmail ? "Approved " : "Pending"}
                            </b>{" "}
                            | Second :{" "}
                            <b>
                              {iorder.secondAppEmail ? "Approved " : "Pending"}
                            </b>
                          </td>
                        )}
                        {iorder.orderStatus === "Rejected" && (
                          <td style={{ textAlign: 'center' }} label="Reject Reason"> {iorder.rejectReason} </td>
                        )}
                        <td style={{ textAlign: 'center' }} label="">
                          {(iorder.orderStatus === "Published" || iorder.orderStatus === "Complete" || iorder.orderStatus === "Shipped") ? (
                            <button
                              className="btn btn-sm btn-outline-success p-3"
                              onClick={() =>
                                handleRepeatRow(iorder, iorder.orderId)
                              }
                            >
                              {" "}
                              Repeat order
                            </button>
                          ) : (
                            <></>
                          )}
                        </td>
                        {/* <td>
                        { userRole === "ROLE_ADMIN" && orderStatusList === "Pending for Approval" ?
                         (iorder.firstAppEmailId === userEmail || iorder.secondAppEmailId === userEmail)?
                          (iorder.firstAppEmailId.indexOf(userEmail) !== -1 &&  iorder.firstAppEmail === true) || (  iorder.secondAppEmail === true) ?
                          <div>  <button
                            className="btn btn-del btn-outline-success" disabled
                          > Approve Order </button>
                            <button
                              className="btn btn-del btn-outline-success" disabled
                            >Reject Order
                            </button></div>:<div>  <button
                              className="btn btn-del btn-outline-success"
                              onClick={() => handleAprove(iorder, iorder.orderId)}
                            > Approve Order </button> <button
                              className="btn btn-del btn-outline-success"
                              onClick={() => { handleOpen(); setOrderId2(iorder.orderId) }}
                            >Reject Order
                            </button></div>:<td></td>:<></>
                        
                    }

                        </td> */}
                     
                        <td style={{ textAlign: 'center' }}>
                          {userRole === "ROLE_ADMIN" && orderStatusList === "Pending for Approval" ?
                          (iorder.firstAppEmailId === userEmail || iorder.secondAppEmailId === userEmail )?
                          (iorder.firstAppEmail == true  &&  iorder.secondAppEmail == true ) ||((iorder.firstAppEmailId === userEmail && iorder.firstAppEmail == true) ||
                          (iorder.secondAppEmailId === userEmail && iorder.secondAppEmail == true)  ) ?
                            <div>  <button
                                  className="btn btn-del btn-outline-success" disabled
                                > Approve Order </button>
                                  <button
                                    className="btn btn-del btn-outline-success" disabled
                                  >Reject Order
                                  </button></div> : <div>  <button
                                    className="btn btn-del btn-outline-success"
                                    onClick={() => handleAprove(iorder, iorder.orderId)}
                                  > Approve Order </button> <button
                                    className="btn btn-del btn-outline-success"
                                    onClick={() => { handleOpen(); setOrderId2(iorder.orderId) }}
                                  >Reject Order
                                  </button></div> :<td></td>:<></>
                          }
                          {iorder.orderStatus === "Rejected" ? (
                            <></>
                          ) : iorder.orderStatus === "Pending"  ? (

                            <button style={{ textAlign: 'center' }}
                              className="btn btn-del btn-outline-success"
                              onClick={() =>
                                handlePublishRow(iorder, iorder.orderId)
                              }>
                              Publish Order
                            </button>

                          ) : (<></>)}


                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <IconButton
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={handleClose}
                              >
                            <i><ImCross size={20} /></i>
                              </IconButton>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Provide Reject Reason
                              </Typography>

                              <FormControl
                                fullWidth
                                style={{ margin: "10px 0" }}
                              >
                                <InputLabel id="rejectReasonLabel"></InputLabel>
                                <Select
                                  labelId="rejectReasonLabel"
                                  id="rejectReasonSelect"
                                  value={rejectReason}
                                  onChange={(e) =>
                                    setRejectReason(e.target.value)
                                  }
                                >
                                  <MenuItem value="Select reason">Select reason</MenuItem>
                                  <MenuItem value="Payment issue">
                                    Payment issue
                                  </MenuItem>
                                  <MenuItem value="Hold by operation team">
                                    Hold by operation team
                                  </MenuItem>
                                  <MenuItem value="Low inventory">
                                    Low inventory
                                  </MenuItem>
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                              </FormControl>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-justify",
                                  marginTop: "10px",
                                }}
                              >  <button
                                onClick={handleReject1}
                                className="btn btn-del btn-outline-success"
                              >
                                  Save
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  className="btn btn-del btn-outline-danger"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </button>
                              </Box>
                            </Box>
                          </Modal>


                          {iorder.orderStatus === "Pending" ? (
                            <button
                              className="btn btn-sm btn-outline-success p-3"
                              style={{ marginLeft: '10px' }}
                              onClick={() => {
                                handleCancelOpen();
                                setRejectReason("Order Cancelled");
                                setOrderId2(iorder.orderId);
                              }}
                            >
                              Cancel Order
                            </button>
                          ) : (
                            <></>
                          )}
                          <Modal
                            open={cancel}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style} style={{ position: "relative" }}>
                              <IconButton
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={handleClose}
                              >
                              <i><ImCross size={20} /></i>
                              </IconButton>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Reject Reason
                              </Typography>

                              <FormControl fullWidth style={{ margin: "10px 0" }}>
                                <InputLabel htmlFor="customReasonInput">Order Cancelled</InputLabel>
                                <TextField
                                  id="customReasonInput"
                                  disabled
                                />
                              </FormControl>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-justify",
                                  marginTop: "10px",
                                }}
                              >
                                <button onClick={handleReject1} className="btn btn-del btn-outline-success">
                                  Save
                                </button>&nbsp;&nbsp;
                                <button className="btn btn-del btn-outline-danger" onClick={handleClose}>
                                  Cancel
                                </button>
                              </Box>
                            </Box>
                          </Modal>
 {iorder.orderStatus === "Rejected" ? (
                            <button
                              className="btn btn-del btn-outline-success"
                              onClick={() =>
                                handleRevokeOrder(iorder, iorder.orderId)
                              }
                            >
                              Reorder
                            </button>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">
                        {orderStatusList} orders are not found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </p>
        </div>
      </div>
    </>
  );
}

export default OrderListPage;
