import React, { useEffect, useState } from "react";
import logo from "../assets/images/Snowman_Logo.png";
import { CiLogin } from "react-icons/ci";
import { Link } from "react-router-dom";
import { BsPersonFill } from "react-icons/bs";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom/dist";
import authService from "../services/auth.service";

const TopNavbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(authService.getCurrentUser());
  const [username, setUsername] = useState(""); // Initialize username state

  // const storedUserData = localStorage.getItem("user");
  // const [isLoggedIn, setIsLoggedIn] = useState(authService.getCurrentUser()); // Check if user is logged in
  // console.log(isLoggedIn)
  
  
  // // Parse the stored JSON data
  // const userData = JSON.parse(storedUserData);
  
  // // Access the username from the parsed data
  // const username = userData.username;
  // console.log(storedUserData)
  const navigate = useNavigate();
  useEffect(() => {
    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    if (userData) {
      const username = userData.username;
      setUsername(username);
    }
  }, [isLoggedIn]); 
  const handleAnchorClick = async () => {
    swal({
      title: "Are you sure?",
      text: "You want to logout your account?",
      icon: "warning",
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        
        swal("Logout!", "Logout Successfully", "success");
        logOut(); // Call the logOut function to clear session storage and navigate
      }
    }).catch((error)=>{
      swal("Error", error.message,"error")
    })
  };

  const logOut = () => {
    
    authService.logout(); // Logout logic, if needed
    sessionStorage.clear();
    localStorage.clear();
    setIsLoggedIn(false);
    setUsername("");
    navigate("/", { replace: true }); // Redirect to the home page
  };

  return (
    <>
      <nav  className="navbar top-nav navbar-expand-lg d-flex justify-content-between " style={{ height: '80px' }}>
        <span style={{ color: 'white', fontFamily: 'math',
    fontSize: "20px"}}>
      {/* Hello <b>{username}</b>, Welcome! */}
      </span>
        {/* <a className="navbar-brand" href="#" style={{ backgroundColor: "#fff", marginLeft: '15px', borderRadius: "30px" }}>
          <img
            src={logo}
            width="70"
            height="30"
            className="d-inline-block align-top mx-2"
            alt="Logo"
          />
        </a> */}
        <div className="logout my-lg-0 mx-3">
          <span className="mx-2">
            <BsPersonFill color="white" size={20} className="person" /><span className="m-1" style={{ color: '#dfdfdf', fontFamily: 'math',
    fontSize: "20px"}}>{username}</span></span>
          <Link to='/' onClick={handleAnchorClick} style={{ color: 'white !important' }}>
            <span><CiLogin fontSize="30px" color="red" style={{ color: "#fff" }} /></span>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default TopNavbar;
