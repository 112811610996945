import React, { useEffect, useState } from "react";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { ImCross } from 'react-icons/im'
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { Link, useLocation } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import "./createOrder.css";
import { saveAs } from "file-saver";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom/dist";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { ArrowLeft } from "@mui/icons-material";
import { API } from "../../services/axiosConfig";
import LoadingSpinner from "../../common/LoadingSpinner";
import AttachmentList from "../../common/AttachmentList";
// import { Button, Modal } from "bootstrap";
import Modal from "@material-ui/core/Modal";
import { Box, Button, IconButton, Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500, // Set your desired height
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Todaysdate = Moment(new Date()).format("DD-MM-YYYY");
const CreateOrder = (props) => {
  const initialState = {

    soNumber: "",
    shipmentNumber: "",
    issync: "",
    firstAppEmailId: "",
    secondAppEmailId: "",
    thirdAppEmailId: "",
    custNo: "",
    location: "",
    customerNo: "",
    orderNo: "",
    storeName: "",
    orderStatus: "Pending",
    orderPlacedDate: "",
    orderDate:"",
    orderDispatchDate: "",
    statusAdmin: "",
    statusUser: "",
    comment: "",
    orderId: 0,
    firstAppEmail: false,
    secondAppEmail: false,
    thirdAppEmail: false,
    userId: sessionStorage.getItem("userId"),
    orderDetailsModelClass: [
      {
        userId: sessionStorage.getItem("userId"),
        orderDetailId: 0,
        sku: "",
        quantity: 0,
        amount: "",
        price: 0,
        serialNo: 0,
        qtyDelivered: 0,
        actualQuantity: 0,
        itemdetailsId: 0
      },
    ],
  };
  const [aproveqtycheck, setAproveqtycheck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const userEmail = sessionStorage.getItem('userEmail');
  const [customerNumbers, setCustomerNumbers] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const { orderId, tempId } = useParams();
  const [itemdetailsIds, setItemdetailsIds] = useState(new Set());
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("Pending");
  const username = sessionStorage.getItem("username");
  const [rows, setRows] = useState([]);
  const [orderData, setOrderData] = useState(initialState);
  const [itemDetails, setItemDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [selectStoreName, setSelectStoreName] = useState("");
  const [options, setOptions] = useState([]);
  const [selectLocationName, setSelectLocationName] = useState("");
  const [locationoptions, setlocationOptions] = useState([]);
  const [selectCustomerName, setSelectCustomerName] = useState("");
  const [custNoptions, setCustNoOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [creditLimitData, seCreditLimitData] = useState([]);
  const [getData, SetGetData] = useState([]);
  const [selectLocationCode, setSelectLocationCode] = useState("");
  const [locationName, setLocationName] = useState("");
  const [customername, setCustomername] = useState("");
  const [customeraddress, setCustomeraddress] = useState("");
  const [customeraddress2, setCustomeraddress2] = useState("");
  const [customerCity, setCustomercity] = useState("");
  const [locationId, setLocationId] = useState("");
  const [aproveButton, setAproveButton] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const userRole = sessionStorage.getItem("userRole");
  const navigate = useNavigate();

  const [rejectReason, setRejectReason] = useState('');

  const ApprovalType_ForApproval = sessionStorage.getItem("ApprovalType_ForApproval");

  useEffect(() => {
 getLocationData(userId);
    if (tempId) {
      GetItembyTempId(tempId);
    }
    if (orderId) {
      GetByOrderId1(orderId);
    }
    getStoreData();
    getCustomerNo();
  }, []);

  useEffect(() => {
   
    if (selectLocationCode) {
      API.get(`customerInfo/${locationId}/${userId}`)
        .then((response) => {
          const dataSet = response.data;
          console.log(dataSet);
          dataSet.sort((a, b) => a.customerName.localeCompare(b.customerName));
          setCustomerNumbers(dataSet);
        }).catch((error) => { 
          swal("Error", error.message,"error")
          console.error(error.message)});
    }
  }, [selectLocationCode]);

  const GetItembyTempId = async (tempId) => {
    try {

      // console.log(userId, "userId")
      setIsLoading(true);
      const response = await API.get(`api/templates/${tempId}`);
      const orderDetails = response.data;
      const today = new Date();
      const date = Moment(today).format("YYYY-MM-DD");
      console.log(date)
      const {
        userId = sessionStorage.getItem("userId"),
        location,
        orderStatus = "Pending",
        orderNo,
        storeName,
        orderDate = date,
        orderDispatchDate,
        soNumber,
        shipmentNumber,
        issync,
        statusAdmin,
        statusUser,
        customerNo,
        comment,
        firstAppEmail = false,
        secondAppEmail = false,
        thirdAppEmail,
        firstAppEmailId,
        secondAppEmailId,
        thirdAppEmailId,
        rejectReason,
        custNo,
        orderPlacedDate = date,
        itemDetails } = orderDetails;

      itemDetails.sort((a, b) => a.orderDetailId - b.orderDetailId);
      const totalAmount = itemDetails.reduce(
        (acc, item) => acc + item.amount,
        0
      );
      const totalQty = itemDetails.reduce(
        (acc, item) => acc + item.qty,
        0
      );
      setOrderData({
        userId,
        location,
        orderStatus,
        orderNo,
        storeName,
        orderDate,
        orderDispatchDate,
        soNumber,
        shipmentNumber,
        issync,
        statusAdmin,
        statusUser,
        customerNo,
        comment,
        firstAppEmail,
        secondAppEmail,
        thirdAppEmail,
        firstAppEmailId,
        secondAppEmailId,
        thirdAppEmailId,
        rejectReason,
        custNo,
        orderPlacedDate,
        orderDetailsModelClass: itemDetails.map((item) => ({
          amount: item.price * item.quantity,
          price: item.price,
          quantity: item.quantity,
          sku: item.sku,
          orderDetailId: item.orderDetailId,
          orderId: item.orderId,
          userId: item.userId,
          serialNo: item.serialNo,
          qtyDelivered: item.qtyDelivered,
          actualQuantity: item.actualQuantity
        })),
      });
      setSelectCustomerName(customerNo);
      const locationResponse = await API.get(`locationbyuserid/${location}`);
      const locationData = locationResponse.data;
      setLocationName(locationData.location);
      setLocationId(locationData.locationId);
      setSelectLocationCode(location);
      const startIndex = customerNo.indexOf("(") + 1;
      const endIndex = customerNo.indexOf(")");
      const result = customerNo.slice(startIndex, endIndex);
      GetCreditData(result);
      const customerInfoResponse = await API.get(
        `customerInfo/${locationData.locationId}/${userId}`
      );
      const customerInfo = customerInfoResponse.data.find(
        (item) => item.customerNo === result
      );

      if (customerInfo) {
        setCustomername(customerInfo.customerName);
        setCustomeraddress(customerInfo.address);
        setCustomeraddress2(customerInfo.address2);
        setCustomercity(customerInfo.city);
        const itemDetailsResponse = await API.get(`items/${result}`);
        const CrditLimitAprovalmail = await API.get(`appMatrix/${result}`);
        if (!CrditLimitAprovalmail.data) {
          console.log(CrditLimitAprovalmail.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }else{
        const responseData = CrditLimitAprovalmail.data[0];

        if (responseData && responseData[ApprovalType_ForApproval] && responseData[ApprovalType_ForApproval].indexOf(userEmail) !== -1) {
          setAproveButton(true);
        }
        else if (ApprovalType_ForApproval === "firstAppEmailAprove") {
          const firstEmailAprove = "firstAppEmail"
          if (responseData && responseData[firstEmailAprove].indexOf(userEmail) !== -1) {
            setAproveButton(true);
          }
        } else
          if (userRole === "ROLE_ADMIN") {
            setAproveButton(true);
          }
          else {
            setAproveButton(false);
          }

        const itemDetail = itemDetailsResponse.data;
        const updatedRows = rows.map((row) => ({ ...row }));
        const newRows = itemDetails.map((item, index) => {
          console.log(item);
          const matchingItem = itemDetail.find(
            (detail) => detail.sku.toLowerCase() === item.sku.toLowerCase()
          );

          setItemdetailsIds(prevIds => {
            const newIds = new Set(prevIds);
            newIds.add({ itemdetailsId: matchingItem.itemdetailsId });
            return newIds;
          });

          return {
            sku: matchingItem.sku,
            skuName: matchingItem.skuName,
            packingDescription: matchingItem.packingDescription,
            uom: matchingItem.uom,
            lotsize: matchingItem.lotsize,
            category: matchingItem.category,
            orderMaxlimit: matchingItem.orderMaxlimit,
            inventory: matchingItem.inventory,
            price: matchingItem.price,
            serialNo: item.serialNo,
            qtyDelivered: item.qtyDelivered,
            actualQuantity: item.actualQuantity,
            qty: item.quantity,
            amount: item.quantity * item.price,
            itemdetailsIds: matchingItem.itemdetailsId,
          };
        });
        // Calculate total amount
        const updatedTotalAmount = newRows.reduce(
          (acc, row) => acc + row.amount,
          0
        );
        setTotalAmount(updatedTotalAmount);

        const updatedTotalQty = newRows.reduce(
          (acc, row) => acc + row.qty,
          0
        );
        setTotalQty(updatedTotalQty);
        // Update rows and itemdetailsIds
        setRows([...updatedRows, ...newRows]);
        setIsLoading(false);
      }

      setIsLoading(false);

    }
    } catch (error) {
     console.error(error);
      setIsLoading(false);
      swal("Error", error.message,"error")
    }
  };
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };
  const GetCreditData = async (result) => {
    try {
      const id = orderData.userId;
      const response = await API.get("limitByUser/" + result);
      if (response.status === 200) {
        const data = response.data;
        seCreditLimitData(data);
      } else {
        // Handle unexpected status code
        console.error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred while fetching credit data:", error);
      setIsLoading(false);
      swal("Error", "An error occurred while fetching credit data:"+error.message,"error")
    }
  };


  const GetByOrderId1 = async (id) => {

    try {
      // console.log(userId, "userId")
      setIsLoading(true);
      const response = await API.get(`order/${id}`);
      const orderDetails = response.data;
      console.log(orderDetails)
      setAproveqtycheck(orderDetails);
      const {
        userId,
        location,
        orderStatus,
        orderNo,
        storeName,
        orderDate,
        orderDispatchDate,
        soNumber,
        shipmentNumber,
        issync,
        statusAdmin,
        statusUser,
        customerNo,
        comment,
        firstAppEmail,
        secondAppEmail,
        thirdAppEmail,
        firstAppEmailId,
        secondAppEmailId,
        thirdAppEmailId,
        rejectReason,
        custNo,
        orderPlacedDate,
        orderDetailsModelClass } = orderDetails;
      setOrderData({
        userId,
        location,
        orderStatus,
        orderNo,
        storeName,
        orderDate,
        orderDispatchDate,
        soNumber,
        shipmentNumber,
        issync,
        statusAdmin,
        statusUser,
        customerNo,
        comment,
        firstAppEmail,
        secondAppEmail,
        thirdAppEmail,
        firstAppEmailId,
        secondAppEmailId,
        thirdAppEmailId,
        rejectReason,
        custNo,
        orderPlacedDate,
        orderDetailsModelClass: orderDetailsModelClass.map((item) => ({
          amount: item.amount,
          price: item.price,
          quantity: item.quantity,
          sku: item.sku,
          orderDetailId: item.orderDetailId,
          orderId: item.orderId,
          userId: item.userId,
          serialNo: item.serialNo,
          qtyDelivered: item.qtyDelivered,
          actualQuantity: item.actualQuantity,
          itemdetailsId: item.itemdetailsId
        })),
      });
      orderDetailsModelClass.sort((a, b) => a.orderDetailId - b.orderDetailId);
      const totalAmount = orderDetailsModelClass.reduce(
        (acc, item) => acc + item.amount,
        0
      );
      const totalQty = orderDetailsModelClass.reduce(
        (acc, item) => acc + item.qty,
        0
      );
      console.log(orderDetailsModelClass, "orderDetailsModelClass");


      // setCheckLimit(totalAmount);
      setStatus(orderStatus);
      setSelectStoreName(storeName);
      setSelectCustomerName(customerNo);
      const locationResponse = await API.get(`locationbyuserid/${location}`);
      const locationData = locationResponse.data;
      setLocationName(locationData.location);
      setLocationId(locationData.locationId);
      setSelectLocationCode(location);
      const startIndex = customerNo.indexOf("(") + 1;
      const endIndex = customerNo.indexOf(")");
      const result = customerNo.slice(startIndex, endIndex);
      GetCreditData(result);
      const customerInfoResponse = await API.get(
        `customerInfo/${locationData.locationId}/${userId}`
      );
      const customerInfo = customerInfoResponse.data.find(
        (item) => item.customerNo === result
      );

      if (customerInfo) {
        setCustomername(customerInfo.customerName);
        setCustomeraddress(customerInfo.address);
        setCustomeraddress2(customerInfo.address2);
        setCustomercity(customerInfo.city);
        const itemDetailsResponse = await API.get(`items/${result}`);
        const CrditLimitAprovalmail = await API.get(`appMatrix/${result}`);
        if (!CrditLimitAprovalmail.data) {
          console.log(CrditLimitAprovalmail.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }else{
        const responseData = CrditLimitAprovalmail.data[0];

        if (responseData && responseData[ApprovalType_ForApproval] && responseData[ApprovalType_ForApproval].indexOf(userEmail) !== -1) {
          setAproveButton(true);
        }
        else if (ApprovalType_ForApproval === "firstAppEmailAprove") {
          const firstEmailAprove = "firstAppEmail"
          if (responseData && responseData[firstEmailAprove].indexOf(userEmail) !== -1) {
            setAproveButton(true);
          }
        } else
          if (userRole === "ROLE_ADMIN") {
            setAproveButton(true);
          }
          else {
            setAproveButton(false);
          }

        const itemDetail = itemDetailsResponse.data;
        const updatedRows = rows.map((row) => ({ ...row }));
        const newRows = orderDetailsModelClass.map((item, index) => {
          console.log(item);
          const matchingItem = itemDetail.find(
            (detail) => detail.sku.toLowerCase() === item.sku.toLowerCase()
          );


          setItemdetailsIds((prevIds) => {

            const newIds = { ...prevIds }; // Create a new object based on the previous state
            newIds[0] = { itemdetailsId: item.itemdetailsId, sku: item.sku }; // Add itemdetailsId and sku with key 0
            return newIds;
          });


          console.log(matchingItem);
          return {
            sku: matchingItem.sku,
            skuName: matchingItem.skuName,
            packingDescription: matchingItem.packingDescription,
            uom: matchingItem.uom,
            lotsize: matchingItem.lotsize,
            category: matchingItem.category,
            orderMaxlimit: matchingItem.orderMaxlimit,
            inventory: matchingItem.inventory,
            price: matchingItem.price,
            serialNo: item.serialNo,
            qtyDelivered: item.qtyDelivered,
            actualQuantity: item.actualQuantity,
            qty: item.quantity,
            quantity: item.quantity,
            amount: item.amount,
            itemdetailsId: matchingItem.itemdetailsId,
          };

        });
        // Calculate total amount
        const updatedTotalAmount = newRows.reduce(
          (acc, row) => acc + row.amount,
          0
        );
        setTotalAmount(updatedTotalAmount);

        const updatedTotalQty = newRows.reduce(
          (acc, row) => acc + row.qty,
          0
        );

        setOrderData({
          userId,
          location,
          orderStatus,
          orderNo,
          storeName,
          orderDate,
          orderDispatchDate,
          soNumber,
          shipmentNumber,
          issync,
          statusAdmin,
          statusUser,
          customerNo,
          comment,
          firstAppEmail,
          secondAppEmail,
          thirdAppEmail,
          firstAppEmailId,
          secondAppEmailId,
          thirdAppEmailId,
          rejectReason,
          custNo,
          orderPlacedDate,
          orderDetailsModelClass: orderDetailsModelClass.map((item) => ({
            amount: item.amount,
            price: item.price,
            quantity: item.quantity,
            sku: item.sku,
            orderDetailId: item.orderDetailId,
            orderId: item.orderId,
            userId: item.userId,
            serialNo: item.serialNo,
            qtyDelivered: item.qtyDelivered,
            itemdetailsId: item.itemdetailsId,
            actualQuantity: item.actualQuantity
          })),
        });
        setTotalQty(updatedTotalQty);
        // Update rows and itemdetailsIds
        setRows([...updatedRows, ...newRows]);
        setIsLoading(false);
      }
    }
    } catch (error) {
      console.error(error);
      swal("Error", error?.message,"error") 
    }
  };

  const getStoreData = async () => {
    try {
      const response = await API.get("storename");
      if (response.status === 200) {
        const data = response.data;
        setOptions(data);
      } else {
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching options:", error);
      swal("Error", error?.message,"error") 
    }
  };

  const getCustomerNo = async () => {
    try {
      const response = await API.get("customerMaster");
      if (response.status === 200) {
        const data = response.data;
        setCustNoOptions(data);
      } else {
        console.error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a non-2xx status code
        console.error(
          "Request was made but received an error response:",
          error.response.status,
          error.response.data
        );
        swal("Error","Request was made but received an error response:"+ error.response.data,"error") 
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "Request was made but no response was received:",
          error.request
        );
        swal("Error","Request was made but no response was received:"+ error.request,"error") 

      } else {
        // Something else happened while setting up the request
        console.error(
          "An error occurred while setting up the request:",
          error.message
        );
        swal("Error","Request was made but no response was received:"+ error.message,"error") 

      }
    }
  };

  const [locationData, setLocationData] = useState([]);


  const getLocationData = async (id) => {
    try {
      const locationIdsToFind = await API.get(`locations/${id}`);

      if (locationIdsToFind.status === 200) {
        const data = locationIdsToFind.data;
        await API.get(`location`).then((res) => {
          SetGetData(res.data);
          const allLocationData = res.data; // Assuming this is an array of location objects

          // Set the location data in state
          setLocationData(allLocationData);

          console.log(allLocationData)
          const locationCodesWithNames = data.map((locationId) => {
            const location = allLocationData.find(
              (item) => item.locationId === locationId
            );

            if (location) {
              return {
                code: location.locationCode,
                name: location.location,
              };
            } else {
              return null;
            }
          });

          const nonNullLocationOptions = locationCodesWithNames.filter(option => option !== null);
      
          if (nonNullLocationOptions.length === 0) {
            swal("Warning","Not assigned any location","warning");
          } else {
            setlocationOptions(nonNullLocationOptions);
            console.log("new1", nonNullLocationOptions);
          }
        });
      } else {
        console.error(`Unexpected status code: ${locationIdsToFind.status}`);
      }
    } catch (error) {
      // Handle errors as needed
      console.error("An error occurred:", error);
      swal("Error", error?.message,"error") 
    }
  };


  const handleReject = (e) => {
    if (orderId) {
      const statusUser = ApprovalType_ForApproval;
      const orderId1 = parseInt(orderId);
      API.post(`/api/orders/reject?orderId=${orderId1}&rejectReason=${rejectReason}`)

        .then((response) => {
          swal("Update!", "Order Reject successfully!", "success")
          setOpen(false);
          navigate("/orderlist", { replace: true });
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle error
        });
    };
  }
  const handleAprove = async () => {
    console.log(orderData);
    console.log(orderId);
    const startIndex = orderData.customerNo.indexOf("(") + 1;
    const endIndex = orderData.customerNo.indexOf(")");
    const result = orderData.customerNo.slice(startIndex, endIndex);
    const isfirstapproved = orderData.firstAppEmail;

    try {
      const res1 = await API.get("limitByUser/" + result);
      console.log(res1.data, "555");

      const updatedTotalAmount = orderData.orderDetailsModelClass.reduce(
        (acc, row) => acc + row.amount,
        0
      );
      if (res1.data[0].avalLimit < updatedTotalAmount) {
        const res2 = await API.get(`appMatrix/${result}`);
        if (!res2.data) {
          console.log(res2.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }else{
        const orderData = res2.data[0];

        // Your other conditions and API calls here
        if (orderData.firstAppEmail.indexOf(userEmail) !== -1) {
          const statusUser = "firstAppEmail";
          await AproveMethod(orderId, statusUser);
        }
        if (orderData.secondAppEmail.indexOf(userEmail) !== -1) {
          const statusUser = "secondAppEmail";
          if(isfirstapproved == true)
            {
              await AproveMethod(orderId, statusUser);
            }
            else
            {
              return swal('Info', "You cannot approve this order because first level approval is still pending", "info");
            }
        }
      }

      } else {

        const res3 = await API.get(`appMatrix/${result}`);
        if (!res3.data) {
          console.log(res3.data);
          return swal('Error', "Publish order cannot proceed. Please provide email.", "error");
        }else{
        console.log(res3.data[0].firstAppEmail);

        let statusUser = "";
        if (res3.data[0].firstAppEmail.indexOf(userEmail) !== -1) {
          statusUser = "firstAppEmailAprove";
          await AproveMethod(orderId, statusUser);
        } else {
      swal("Error!", "Please note that this order is not applicable for approval!", "warning");
        }


      }
      navigate("/orderlist", { replace: true });
    }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", error?.message,"error") 
      // Handle error
    }
  };
  const AproveMethod = async (orderId, statusUser) => { 
   
    await API.post(
      `/api/orders/approvebyUserCreditmail?orderId=${orderId}&statusUser=${statusUser}`)
      .then((response) => {
        API.put(`orders/${orderId}`, orderData)
        swal("Update!", "Order Approve successfully!", "success");
        GetByOrderId1(orderId);
        navigate("/orderlist", { replace: true});
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error
      });
  };

  // const AproveMethod = async (orderId, statusUser) => {
  //   await API.post(
  //     `/api/orders/approvebyUserCreditmail?orderId=${orderId}&statusUser=${statusUser}`
  //   )
  //     .then((response) => {
  //       const approveQtyCheck = orderData.orderDetailsModelClass.every((index) => {
  //         return index.quantity != index.approveQtyCheck;
  //       });
  //       console.log(approveQtyCheck);
  //       console.log("itemdata",orderData.orderDetailsModelClass);

  //       if (approveQtyCheck) {

  //         const quantityValidation = orderData.orderDetailsModelClass.every((index) => {
  //           return index.quantity > 0 && index.quantity !== "";
  //         });

  //         if (quantityValidation) {
  //           orderData.orderStatus = "Published";
  //           API.put(`orders/${orderId}`, orderData);
  //         }
  //       }
  //       swal("Update!", "Order Approve successfully!", "success");
  //       GetByOrderId1(orderId);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       // Handle error
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!orderId) {

      const fieldErrors = {};
      if (selectLocationCode === "") {
        fieldErrors.selectLocationCode = "Please select a location code.";
      } else if (selectCustomerName === "") {
        fieldErrors.selectCustomerName = "Please select a customer.";
      }

      if (totalAmount === "") {
        alert("Quantity is not added");
        fieldErrors.totalAmount = "Order number is required.";

      }

      if (Object.keys(fieldErrors).length > 0) {
        // Update the state with error messages
        setFieldErrors(fieldErrors);
        console.log("Validation failed");
        return;
      }
      console.log(orderData.orderDetailsModelClass.length);

      if (orderData.orderDetailsModelClass.length != 0) {

        const quantityValidation = orderData.orderDetailsModelClass.some((index) => {
          return index.quantity <= 0 || index.quantity === "" || index.qty === "" || index.qty <= 0;
        });
        if (quantityValidation === false) {
          setIsLoading(true);
          // if (creditLimitData[0].avalLimit >= totalAmount) {
          const prefix = orderData.location.substring(0, 3);
          API.post(`ordernumber/${prefix}`)
            .then((response) => {
              console.log(response.data);
              const updatedOrderData = { ...orderData, orderNo: response.data };
              setOrderData(updatedOrderData);
              const endpoint = "order";
              fetch(`${API.defaults.baseURL}${endpoint}`, {
                // fetch('http://localhost:5052/order', {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedOrderData),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.status == 400) {
                    swal("error!", data.error);
                  }
                  swal("Save!", "Order saved successfully!", "success").then(
                    (willDelete) => {
                      if (willDelete) {
                        handleReset();
                        sessionStorage.setItem("orderStatusList", "Pending");

                        navigate("/orderlist", { replace: true });
                      }
                    }
                  );
                  const id = sessionStorage.getItem("userId");

                  if (data) {
                    sendEmailToAdmin(data);
                  attachmentupload(data);
                  }
                }
                );
              setIsLoading(false);
            })

            .catch((error) => {
              alert(error);
              setIsLoading(false);

              console.error("Error saving order:", error);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          swal("Validation!", "Enter valid item with Qunatity!", "error");
        }
      } else {
        swal("Validation!", "Select the item!", "error");
      }
    } else {
      const id = orderId;
      console.log(orderData);
      const quantityValidation = orderData.orderDetailsModelClass.some((index) => {
        return index.quantity <= 0 || index.quantity === "";
      });
      console.log(quantityValidation);
      if (quantityValidation === false) {
        API.put(`orders/${id}`, orderData);
        swal("Update!", "Order updated successfully!", "success")
          .then((willDelete) => {
            if (willDelete) {
              const id = sessionStorage.getItem("userId");

              handleReset();
              navigate("/orderlist", { replace: true });
            }
          })
          .catch((error) => {
            alert(error);
            console.error("Error saving order:", error);
          });

      } else {
        setIsLoading(false);
        swal("Validation!", "Qunatity is zero!", "error");
      }
    }

  };

  const sendEmailToAdmin = (Data) => {
    const orderId = Data; // Replace with the actual orderId
    API.post('/sendmailSubmitorder', null, {
      headers: {
        'orderId': orderId,
        'userEmail': userEmail,
      },
    }).then((response) => response.json())
      .then((data) => {
        console.log(data); // Handle the response as needed
      })
      .catch((error) => {
        console.error(error);
      });
  };




  const handleReset = () => {
    setOrderData(initialState);
    setItemDetails([]);
    setSelectLocationName([]);
    setSelectCustomerName([]);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        itemdetailsId: 0,
        sku: "",
        skuName: "",
        packingDescription: "",
        uom: "",
        category: "",
        lotsize: "",
        orderMaxlimit: "",
        qty: 0,
        serialNo: rows.length + 1,
        price: 0,
        amount: 0,
        qtyDelivered: 0,
        actualQuantity: 0
      },
    ]);
    console.log([...rows]);
  };

  const handleDeleteRow = (index) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete your item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        for (let i = index; i < updatedRows.length; i++) {
          updatedRows[i].serialNo = updatedRows[i].serialNo - 1;
        }

        // Update the rows state first
        setRows(updatedRows);
        const mappedArray = updatedRows.map((obj) => ({
          sku: obj.sku,
          qty: obj.qty,
          serialNo: obj.serialNo,
          price: obj.price,
          amount: obj.amount,
          serialNo: obj.serialNo,
          quantity: obj.qty,
          qtyDelivered: obj.qtyDelivered,
          actualQuantity: obj.actualQuantity
        }));
        console.log(mappedArray);
        let totalAmount = 0;
        for (let i = 0; i < updatedRows.length; i++) {
          updatedRows[i].amount = updatedRows[i].qty * updatedRows[i].price;
          totalAmount += updatedRows[i].amount;
        }
        setTotalAmount(totalAmount);
        let totalQty = 0;
        for (let i = 0; i < updatedRows.length; i++) {
          updatedRows[i].qty = updatedRows[i].qty;
          totalQty += updatedRows[i].qty;
        }
        console.log(mappedArray);
        setTotalQty(totalQty);
        // Now update the orderData state using mappedArray
        setOrderData({ ...orderData, orderDetailsModelClass: mappedArray });

        swal("Deleted!", "Your order item has been deleted!", "success");
      }
    });
  };

  const attachmentupload = async (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("orderId", data);

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      await API.post("/uploadatt", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle successful upload, e.g., show a success message
      console.log("Attachments uploaded successfully");
    } catch (error) {
      // Handle upload error, e.g., show an error message
      console.error("Error uploading attachments:", error);
      // swal("Error", "Error uploading attachments:"+error?.message,"error") 
    }
  };


  const valueInputChange1 = (e, index, fname) => {

    let amountstore;
    if (fname === "qty") {
      setRows({ ...rows, quantity: 0 });
      const { value } = e.target;
      const integerValue = Number(value);
      const updatedRows = [...rows];
      updatedRows[index].qty = integerValue;
      updatedRows[index].amount = integerValue * updatedRows[index].price;
      amountstore = integerValue * updatedRows[index].price;
      console.log(amountstore);
      let totalAmount = 0;
      for (let i = 0; i < updatedRows.length; i++) {
        updatedRows[i].amount = updatedRows[i].qty * updatedRows[i].price;
        totalAmount += updatedRows[i].amount;
      }
      setTotalAmount(totalAmount);

      let totalQty = 0;
      for (let i = 0; i < updatedRows.length; i++) {
        updatedRows[i].qty = updatedRows[i].qty;
        totalQty += updatedRows[i].qty;
      }
      setTotalQty(totalQty);

      setRows(updatedRows);
    } else {

      const updatedRows = [...rows];
      updatedRows[index].sku = e.target.value.toUpperCase();;
      setRows(updatedRows);

      const sku = updatedRows[index].sku;
      const quantity = updatedRows[index].qty;

      if (sku === "") {
        // If no data is found for the SKU, clear all rows
        updatedRows[index].skuName = "";
        updatedRows[index].packingDescription = "";
        updatedRows[index].uom = "";
        updatedRows[index].lotsize = "";
        updatedRows[index].category = "";
        updatedRows[index].orderMaxlimit = "";
        updatedRows[index].inventory = "";
        updatedRows[index].price = 0;
        updatedRows[index].amount = 0;
        updatedRows[index].qty = 0;
        updatedRows[index].itemdetailsId = 0;
        setRows(updatedRows);

      } else {
        const startIndex = selectCustomerName.indexOf("(") + 1;
        const endIndex = selectCustomerName.indexOf(")");
        const result = selectCustomerName.slice(startIndex, endIndex);

        if (result) {
          API.get(`items/${result}`).then((response) => {
            setItemDetails(response.data);

            const foundData = response.data.find((item) => item.sku.toLowerCase() === sku.toLowerCase());

            if (foundData) {
              // Update the rows as before
              updatedRows[index].skuName = foundData.skuName;
              updatedRows[index].packingDescription = foundData.packingDescription;
              updatedRows[index].uom = foundData.uom;
              updatedRows[index].lotsize = foundData.lotsize;
              updatedRows[index].category = foundData.category;
              updatedRows[index].orderMaxlimit = foundData.orderMaxlimit;
              updatedRows[index].inventory = foundData.inventory;
              updatedRows[index].price = foundData.price;
              updatedRows[index].amount = foundData.price * foundData.quantity;
              updatedRows[index].qty = foundData.quantity;
              updatedRows[index].itemdetailsId = foundData.itemdetailsId;
              setItemdetailsIds((prevIds) => {
                const newIds = { ...prevIds }; // Create a new object based on the previous state
                newIds[0] = { itemdetailsId: foundData.itemdetailsId, sku: foundData.sku }; // Add itemdetailsId and sku with key 0
                return newIds;
              });
            }

            let totalAmount = 0;
            for (let i = 0; i < updatedRows.length; i++) {
              totalAmount += updatedRows[i].amount;
            }
            setTotalAmount(totalAmount);

            let totalQty = 0;
            for (let i = 0; i < updatedRows.length; i++) {
              totalQty += updatedRows[i].qty;
            }
            setTotalQty(totalQty);
          });
        }
      }

    }

    const mappedArray = rows.map((obj) => ({
      itemdetailsId: obj.itemdetailsId,
      sku: obj.sku,
      quantity: obj.qty,
      price: obj.price,
      amount: obj.amount,
      serialNo: obj.serialNo,
      qtyDelivered: obj.qtyDelivered,
      actualQuantity: obj.actualQuantity
      
    }));
    console.log(mappedArray, "mappedArray");
    setOrderData({ ...orderData, orderDetailsModelClass: mappedArray });
  };
  const renderOptions = () => {
    return options.map((option) => (
      <option key={option.id} value={option.StoreMasterId}>
        {option.storeName}
      </option>
    ));
  };

  const handleLocationCodeChange = (e) => {

    const selectedCode = e.target.value;

    if (rows.length > 0) {
      swal({
        title: "Change Location code?",
        text: "Do you want to remove items?",
        icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        console.log(result);
        if (result == true) {

          setSelectLocationCode(selectedCode);
          rows.splice(0, rows.length);
          // Update the state to trigger re-render
          setRows([...rows]);
        } else {

          // User clicked 'No', do nothing
        }
      });
    } else {

      setSelectLocationCode(selectedCode);
      setOrderData({
        ...orderData,
        location: selectedCode,
      });
      const foundItem = getData.find((element) => {
        const value1 = element.locationCode == selectedCode;
        return value1; // This is what will be returned when the condition is met
      });
      console.log(foundItem.location);

      if (foundItem) {
        setLocationName(foundItem.location);
        setLocationId(foundItem.locationId);
        console.log(foundItem.locationId)
        // setOrderData({ ...orderData, location: foundItem.location });
      } else {
        setLocationName("");
      }
    }
  };

  const handleOptionChange3 = (event) => {
    const selectedCode = event.target.value;
    console.log(rows);
    if (rows.length > 0) {
      swal({
        title: "Change Customer Number?",
        text: "Your items will be removed",
        icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        console.log(result);
        if (result == true) {
          console.log("fgfdg");
          setSelectCustomerName(selectedCode);
          // User clicked 'Yes', so remove items from rows
          rows.splice(0, rows.length);
          // Update the state to trigger re-render
          setRows([...rows]);
          seCreditLimitData([]);
        } else {
          // User clicked 'No', do nothing
        }
      });
    } else {
      setSelectCustomerName(selectedCode);
      console.log(selectedCode);
      const startIndex = selectedCode.indexOf("(") + 1;
      const endIndex = selectedCode.indexOf(")");
      const result = selectedCode.slice(startIndex, endIndex);
      if (result) {
        GetCreditData(result);
        API.get(`items/${result}`)
          .then((response) => setItemDetails(response.data))
          .catch((error) => console.error(error));
      }

      const today = new Date();
      const date = Moment(today).format("YYYY-MM-DD");
      console.log(date)

      const selectedCustomer = customerNumbers.find(
        (option) => option.customerNo === result
      );
      if (selectedCustomer) {
        setCustomername(selectedCustomer.customerName);
        setCustomeraddress(selectedCustomer.address);
        setCustomeraddress2(selectedCustomer.address2);
        setCustomercity(selectedCustomer.city);
        setOrderData({
          ...orderData,
          customerNo: selectedCode,
          custNo: result,
          orderPlacedDate: date,
          orderDate: date

        });

      } else {
        setCustomername("");
        setCustomeraddress("");
        setCustomeraddress2("");
        setCustomercity("");
      }
    }
  };

  const rendercustomerOptions = () => {
    if(customerNumbers){
    return customerNumbers.map((option) => (
      <option
        key={option.id}
        value={`${option.customerName} (${option.customerNo})`}
      >
        {`${option.customerName} (${option.customerNo}) (${option.address} ${option.address2} ${option.city})`}
      </option>
    ));
  }else{
    return swal ("Warning","customer no are not assigned", "warning")
  }
  };


  const renderlocationOptions = () => {
    return locationoptions.map((location) => {
      if (location && location.code) {
        return (
          <option key={location.code} value={location.code}>
            {`${location.name}  (${location.code})`}
          </option>
        );
      } else {
        return null; // Skip rendering if location or location.code is null
      }
    });
  };


  const [suggestionOptions, setSuggestionOptions] = useState([]);

  const handleSuggestionChange = (index, selectedOption) => {

    console.log(selectedOption);
    const updatedRows = [...rows];
    updatedRows[index].skuName = selectedOption;
    if (selectedOption == "") {
      const updatedRows = [...rows];
      setRows(updatedRows);

    } else {

      setRows(updatedRows);
      // if(selectCustomerName){
      const startIndex = selectCustomerName.indexOf("(") + 1;
      const endIndex = selectCustomerName.indexOf(")");
      const result = selectCustomerName.slice(startIndex, endIndex);
      if (result) {
        API.get(`items/${result}`).then((response) => {
          setItemDetails(response.data);
          const filteredOptions = itemDetails.find((item) =>
            item.skuName.toLowerCase().includes(selectedOption.toLowerCase())
          );
          console.log(filteredOptions);
          if (filteredOptions) {
            const updatedRows = [...rows];
            updatedRows[index].sku = filteredOptions.sku;
            updatedRows[index].packingDescription =
              filteredOptions.packingDescription;
            updatedRows[index].uom = filteredOptions.uom;
            updatedRows[index].lotsize = filteredOptions.lotsize;
            updatedRows[index].category = filteredOptions.category;
            updatedRows[index].orderMaxlimit = filteredOptions.orderMaxlimit;
            updatedRows[index].inventory = filteredOptions.inventory;
            updatedRows[index].price = filteredOptions.price;
            // updatedRows[index].amount = filteredOptions.price;
            updatedRows[index].amount = filteredOptions.price * filteredOptions.quantity;
            updatedRows[index].qty = filteredOptions.quantity;
            updatedRows[index].itemdetailsId = filteredOptions.itemdetailsId;
            setItemdetailsIds((prevIds) => {
              const newIds = { ...prevIds }; // Create a new object based on the previous state
              newIds[0] = { itemdetailsId: filteredOptions.itemdetailsId, sku: filteredOptions.sku }; // Add itemdetailsId and sku with key 0
              return newIds;
            });

          }
          console.log("itemdetailsIds", itemdetailsIds);
          let totalAmount = 0;
          let totalQty = 0;
          for (let i = 0; i < updatedRows.length; i++) {
            if (updatedRows[i].skuName === "") {
              // If skuName is empty, set amount to 0 for that row
              updatedRows[i].amount = 0;
              updatedRows[i].qty = 0;
            }
            totalAmount += updatedRows[i].amount;
            totalQty += updatedRows[i].qty;
          }
          setTotalAmount(totalAmount);
          setTotalQty(totalQty);

        });
        setRows(updatedRows);
        const mappedArray = rows.map((obj) => ({
          itemdetailsId: obj.itemdetailsId,
          sku: obj.sku,
          quantity: obj.qty,
          price: obj.price,
          amount: obj.amount,
          serialNo: obj.serialNo,
          qtyDelivered: obj.qtyDelivered,
          actualQuantity: obj.actualQuantity
        }));

        console.log("mappedArray", mappedArray);
        setOrderData({ ...orderData, orderDetailsModelClass: mappedArray });
        console.log("orderData", orderData);

      }
    }
    let totalAmount = 0;
    let totalQty = 0;
    for (let i = 0; i < updatedRows.length; i++) {
      if (updatedRows[i].skuName === "") {
        // If skuName is empty, set amount to 0 for that row
        updatedRows[i].amount = 0;
        updatedRows[i].qty = 0;
      }
      totalAmount += updatedRows[i].amount;
      totalQty += updatedRows[i].qty;
    }
    setTotalAmount(totalAmount);
    setTotalQty(totalQty);
  };

  const handleSuggestionsFetchRequested = ({ index, value }) => {
    const filteredOptions = itemDetails.filter((item) =>
      item.skuName.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestionOptions(filteredOptions);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestionOptions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.skuName;

  const renderSuggestion = (suggestion) => <span>{suggestion.skuName}</span>;

  const Columns = [
    { label: "Sr No.", width: " 20px", isvisible: "" },
    { label: "ITEM CODE", width: " 10%", isvisible: "" },
    // { label: "SKU NAME", width: "" },
    { label: " ITEM DESCRIPTION", width: "20%", isvisible: "" },
    { label: "UOM", isvisible: "" },
    { label: "CATEGORY" },
    //  { label: "INVENTORY" },
    { label: "QUANTITY", width: "10%", isvisible: "" },
    { label: "QUANTITY SHIPPED", width: "10%", isvisible: orderData.orderStatus !== "Shipped" ? "hideheader" : "." },
    { label: "Fill Rate %", width: "10%", isvisible: orderData.orderStatus !== "Shipped" ? "hideheader" : "." },
    { label: "PRICE", isvisible: "" },
    { label: "AMOUNT", width: "10%", isvisible: "" },
    { label: (orderData.orderStatus !== "Complete" && orderData.orderStatus !== "Published") ? "ACTION" : ".", isvisible: "" },
  ];
  
  const handleFileDrop = (acceptedFiles) => {
    const itemsNotAdded = [];
    const reader = new FileReader();
    reader.onload = function (e) {
      setIsLoading(true);
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      // Check if the Excel file is empty
      if (jsonData.length === 0 || jsonData[0].length === 0) {
        setIsLoading(false);
        swal("Error!", "The uploaded Excel file is empty.", "error");
        return;
      }
  
      const headers = jsonData[0];
      const requiredHeaders = ["ITEM_CODE", "ITEM_DESCRIPTION", "QUANTITY"];
      const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
  
      // Check if the Excel file has the correct headers
      if (missingHeaders.length > 0) {
        setIsLoading(false);
        swal("Error!", `The uploaded Excel file is missing the following headers: ${missingHeaders.join(", ")}.`, "error");
        return;
      }
  
      const rowdata = jsonData.slice(1);
      const startIndex = selectCustomerName.indexOf("(") + 1;
      const endIndex = selectCustomerName.indexOf(")");
      const result = selectCustomerName.slice(startIndex, endIndex);
  
      API.get(`items/${result}`).then((res) => {
        // Check if the response data is empty
        if (!res.data || res.data.length === 0) {
          setIsLoading(false);
          swal("Error!", "No matching items found for the selected customer no.", "error");
          return;
        }
  
        const newRowsFromExcel = rowdata.reduce((accumulator, row) => {
          const sku = row[headers.indexOf("ITEM_CODE")];
          const qty = row[headers.indexOf("QUANTITY")];
  
          // Ensure sku is defined before calling toLowerCase()
          if (sku && qty && qty > 0) {
            const foundData = res.data.find((item) =>
              item.sku.toLowerCase().includes(sku.toLowerCase())
            );
  
            if (foundData) {
              const newRow = {
                sku: row[headers.indexOf("ITEM_CODE")] || "",
                skuName: row[headers.indexOf("ITEM_DESCRIPTION")] || "",
                qty: row[headers.indexOf("QUANTITY")] || 0,
              };
              newRow.itemdetailsId = foundData.itemdetailsId;
              newRow.price = foundData.price;
              newRow.skuName = foundData.skuName;
              newRow.uom = foundData.uom;
              newRow.category = foundData.category;
              newRow.qty = row[headers.indexOf("QUANTITY")] || 0;
              newRow.quantity = row[headers.indexOf("QUANTITY")] || 0;
              newRow.amount = newRow.qty * foundData.price;
              newRow.serialNo = rows.length + accumulator.length + 1;
              newRow.qtyDelivered = 0;
              newRow.actualQuantity = 0;
              setItemdetailsIds((prevIds) => {
                const newIds = { ...prevIds };
                newIds[0] = { itemdetailsId: foundData.itemdetailsId, sku: foundData.sku };
                return newIds;
              });
              accumulator.push(newRow);
            } else {
              itemsNotAdded.push(sku);
            }
          }
          return accumulator;
        }, []);
  
        // Show an alert if no matching items were found
        if (newRowsFromExcel.length === 0) {
          setIsLoading(false);
          swal("Error!", "No matching items found for the selected customer no.", "error");
          return;
        }
  
        // Show an alert if some items were not added
        if (itemsNotAdded.length > 0) {
          swal(
            "Error!",
            `The following items were not added to the table: ${itemsNotAdded.join(", ")}`
          );
        }
  
        const combinedRows = [...rows, ...newRowsFromExcel];
        console.log(combinedRows);
        const totalAmount = combinedRows.reduce(
          (total, row) => total + row.amount,
          0
        );
        const totalQty = combinedRows.reduce(
          (total, row) => total + row.qty,
          0
        );
        setTotalAmount(totalAmount);
        setTotalQty(totalQty);
        setRows(combinedRows);
        setOrderData({ ...orderData, orderDetailsModelClass: combinedRows });
  
        setIsLoading(false);
        swal("File Import!", "Completed", "success");
      }).catch((error) => {
        setIsLoading(false);
        swal("Error!", "Failed to fetch data. Please try again.", "error");
      });
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);
  };
  

const exportToExcel = () => {
  setIsLoading(true);
  const headers = [
    "ITEMID",
    "CATEGORY",
    "ITEM_CODE",
    "ITEM_DESCRIPTION",
    "UOM",
    "QUANTITY",
  ];
  const startIndex = selectCustomerName.indexOf("(") + 1;
  const endIndex = selectCustomerName.indexOf(")");
  const result = selectCustomerName.slice(startIndex, endIndex);

  // API.get(`items/${result}`).then((res) => {
    API.get(`items/${result}`).then((res) => {
    // Check if response data is empty or null
    if (!res.data || res.data.length === 0) {
      setIsLoading(false);
      swal("No Items!", "There are no items available for this customer no.", "warning");
      return;
    }

    // Sort the itemDetails array in ascending order based on the "ITEM_CODE" column
    res.data.sort((a, b) => a.sku.localeCompare(b.sku));
    const dataArray = [
      headers,
      ...res.data.map((item) => [
        item.itemdetailsId,
        item.category,
        item.sku,
        item.skuName,
        item.uom,
        item.quantity,
        item.serialNo,
        item.qtyDelivered,
        item.actualQuantity
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Order Data");
    const excelBuffer = XLSX.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    setIsLoading(false);
    swal("Download!", "Export Excel successfully!", "success");
    saveAs(excelBlob, "orderModel.xlsx");
  }).catch((error) => {
    setIsLoading(false);
    swal("Error!", "Failed to fetch data. Please try again."+error, "error");
  });
};



  const handleSaveTemplate = async () => {

    console.log(orderData.orderDetailsModelClass)
    const itemMastersArray = orderData.orderDetailsModelClass.map((order) => {
      // Extract relevant properties from order
      const { itemdetailsId, quantity, serialNo } = order;
      // Return an object containing extracted properties
      return { itemdetailsId, quantity, serialNo };
    });
    console.log(itemMastersArray);
    if (itemMastersArray.length != 0) {
      if (itemMastersArray[0].itemdetailsId != 0) {
        try {
          const templateName = prompt("Enter the name for the template:");
          const templateData = {
            templateName,
            itemMasters: itemMastersArray,
            customerNo: orderData.customerNo,
            location: orderData.location,
          };
          await API.post("api/templates", templateData);
          swal("Success!", "Template saved successfully!", "success");
        } catch (error) {
          console.error("Error saving template:", error);
          swal("Error!", "Failed to save the template!"+error.message, "error");
        }
      } else {
        swal("Validation!", "Select the item!", "error");
      }
    }
    else {
      swal("Validation!", "Select the item!", "error");
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".xlsx, .xls",
    onDrop: handleFileDrop,

  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <div className="create-order">
      {/* <Sidebar/> */}
      <div className="nav-bar">
        <div className="pt-2 pb-2">
          {isLoading == true ? <LoadingSpinner /> : null}

          <h4
            className="h4-heading"
            style={{
              fontWeight: "400",
              color: "black",
              marginLeft: "10px",
              fontSize: "30px",
            }}
          >
            <Link to="/Dashboard" style={{ textDecoration: "none" }}>
              {/* <ArrowLeft /> Back */}
              <button className="btn custom-button btn-contai align-bottom mx-1"
                style={{
                  zIndex: "99999",
                  top: "-60px",
                  position: "absolute",
                  marginTop: "0px;",
                  alignSelf: "left",
                  color: "white"
                }}>
                <ArrowLeft /> Back
              </button>
            </Link>

            <div className="forheaderdev" >
              <div className="headerdivtitle" style={{ top: "0px", position: "absolute", color: "white" }}>
                <h5>
                  <b style={{ color: "black" }}>{orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval" ? (
                    "Order Details"
                  ) : (
                    "Create/Update New Order"
                  )}</b>
                </h5>
              </div>
            </div>
          </h4>

        </div>
        <span></span>
      </div>
      <div>
        <div className="order-body pt-0">
          <div className="order-details-header">
            <h5
              style={{
                fontFamily: "initial !important",
                fontWeight: "400 !important",
                color: "#312267",
              }}
            >
              {/* Order details */}
            </h5>
          </div>

          <div>
            <form className="form">
              {/* {orderData.orderStatus} */}
              <div class="row">
                <div className="col-lg-6 col-md-6 col-sm-12  px-5">
                  <label htmlFor="locationCode" className="px-1">
                    Location Code:
                  </label>
                  {orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval" ? (
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      value={selectLocationCode}
                      required
                    />
                  ) : (
                    <select
                      className={`form-control input-field  ${selectLocationCode === "" ? "is-invalid" : ""
                        }`}
                      id="locationCode"
                      value={selectLocationCode}
                      onChange={handleLocationCodeChange}

                      required
                    >
                      <option disabled value="">
                        Select location Code
                      </option>
                      {renderlocationOptions()}
                    </select>
                  )}
                  {fieldErrors.selectLocationCode && (
                    <div className="invalid-feedback">
                      {fieldErrors.selectLocationCode}
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12  px-5">
                  <label htmlFor="customerNo" className="px-1">
                    Location Name :
                  </label>
                  <input
                    disabled
                    placeholder="Location Name"
                    className={`form-control input-field`}
                    type="text"
                    id="locationName"
                    value={locationName} // Use the locationName state here
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 px-5">
                  <label htmlFor="customerNo" className="px-1">
                    Customer No:
                  </label>

                  {orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval" ? (
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      value={selectCustomerName}
                      required
                    />
                  ) : (selectLocationCode ? 
                    <select
                      className={`form-control input-field bcolor ${selectCustomerName === "" ? "is-invalid" : ""
                        }`}
                      id="customerNo"
                      value={selectCustomerName}
                      onChange={handleOptionChange3}
                      required
                    >
                      <option disabled value="">
                        Select Customer Number
                      </option>
                      {rendercustomerOptions()}
                    </select>:  <select
                    disabled
                    placeholder="select customer no"
                      className={`form-control input-field 
                        }`}
                      id="customerNo"
                     >
                      <option  value="">
                        Select Customer Number
                      </option>
                    </select>
                  )}
                  {fieldErrors.selectCustomerName && (
                    <div className="invalid-feedback">
                      {fieldErrors.selectCustomerName}
                    </div>
                  )}

                  <div >
                    {orderId ?
                      <label htmlFor="customerNo" className="px-1">
                        Order Date : {Moment(orderData.orderPlacedDate).format("DD-MM-YYYY")}
                      </label> : <label htmlFor="customerNo" className="px-1">
                        Order Date : {Todaysdate}
                      </label>
                    }

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12  px-5">
                  <label htmlFor="customerName" className="px-1">
                    Customer Details :
                  </label>
                  <input
                    disabled
                    placeholder=""
                    className={`form-control input-field`}
                    type="text"
                    id="customername"
                    value={customername} // Use the locationName state here
                    required
                  />
                  <input
                    disabled
                    placeholder=""
                    className={`form-control input-field`}
                    type="text"
                    id="customeraddress"
                    value={customeraddress} // Use the locationName state here
                    required
                  />
                  <input
                    disabled
                    placeholder=""
                    className={`form-control input-field`}
                    type="text"
                    id="customeraddress2"
                    value={customeraddress2} // Use the locationName state here
                    required
                  />
                  <input
                    disabled
                    placeholder=""
                    className={`form-control input-field`}
                    type="text"
                    id="customercity"
                    value={customerCity} // Use the locationName state here
                    required
                  />
                </div>
                {orderId ? (
                  <div className="col-lg-6 col-md-6 col-sm-12  px-5">
                    <label htmlFor="orderNo" className="px-1">
                      Order No:{" "}
                    </label>
                    <input
                      placeholder="Order No"
                      disabled
                      className={`form-control input-field ${orderData.orderNo ? "" : "is-invalid"
                        }`}
                      type="text"
                      id="myInput"
                      value={orderData.orderNo}
                      // onChange={}
                      required
                    />
                    {fieldErrors.orderNo && (
                      <div className="invalid-feedback">
                        {fieldErrors.orderNo}
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
          <div className="row px-sm-5" style={{ margin: "10px" }}>

            <div class="col-sm-10 col-md-2" style={{ padding: "5px" }}>
              {selectCustomerName ? (

                orderData.orderStatus == "Pending" || (orderData.orderStatus === "Pending for Approval" && userRole === "ROLE_ADMIN") ? (
                  <button
                    className="form-control custom-button"
                    onClick={handleAddRow}
                  >
                    + Add Item
                  </button>
                ) : (<></>)

              ) : (
                <></>
              )}
            </div>

            <div class="col-sm-12 col-md-2" style={{ padding: "5px" }}  {...getRootProps()}>
              <input {...getInputProps()} />
              {selectCustomerName ? (
                orderData.orderStatus == "Pending" ? (
                  <button className="form-control custom-button">
                    Import Items
                  </button>
                ) : (<></>)
              ) : (
                <></>
              )}
            </div>

            <div class="col-sm-12 col-md-2" style={{ padding: "5px" }}>
              {selectCustomerName ? (
                orderData.orderStatus == "Pending" ? (
                  <button className="form-control custom-button" onClick={exportToExcel}                  >
                    Export Items
                  </button>
                ) : (<></>)
              ) : (
                <></>
              )}
            </div>
          </div>


          <div className="order-details-header">
            <h5 style={{ fontWeight: "400 !important", color: "#312267" }}>
              Item Details
            </h5>
          </div>
          <div class="table-container p-2">
            <table className="table table-hover thead-primary text-start tabled-bordered">
              <thead
                className="thead-dark"
                style={{ position: "sticky", top: "0", zIndex: "40" }}
              >
                <tr>
                  {Columns.map((item) => (
                    <th
                      scope="col"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={item.label}
                      width={item.width}
                      className={item.isvisible}

                    >
                      {item.label
                        ? `${String(item.label)?.length >= 900
                          ? String(item.label)
                            ?.substring(0, 8)
                            ?.concat("...")
                          : item.label
                        }`
                        : "N/A"}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.length == 0 ? (
                  <tr>
                    <td colSpan="9">No orders found.</td>
                  </tr>
                ) : (
                  rows.sort((a, b) => a.serialNo > b.serialNo ? 1 : -1).map((row, index) => (


                    <tr key={index}>
                      <td style={{ width: "10px" }} label="SerialNo">
                        {row.serialNo}
                      </td>
                      <td label="Sku">

                        {(orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval")  && userRole !== "ROLE_ADMIN"   ? (
                          <input
                            className="form-control"
                            disabled
                            type="text"
                            value={row.sku}
                            required
                          />
                        ) : (
                          <input
                            className="form-control"
                            type="text"
                            value={row.sku}
                            onChange={(e) => valueInputChange1(e, index, "sku")}
                            required
                          />
                        )}
                      </td>

                      <td label="Item Details">
                        {" "}

                        {(orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval") && userRole !== "ROLE_ADMIN"  ? (
                          <input
                            className="form-control"
                            disabled
                            type="text"
                            value={row.skuName}
                            required
                          />
                        ) : (
                          <Autosuggest
                            suggestions={suggestionOptions.slice(0, 20)}
                            onSuggestionsFetchRequested={
                              handleSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              handleSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            renderSuggestionsContainer={({
                              containerProps,
                              children,
                              query,
                            }) => (
                              <div
                                {...containerProps}
                                className="suggestions-container"
                              >
                                <ul>{children}</ul>
                              </div>
                            )}
                            inputProps={{
                              value: row.skuName,
                              onChange: (e, { newValue }) =>
                                handleSuggestionChange(index, newValue),
                              className: "form-control",
                            }}
                          />
                        )}

                      </td>
                      <td label="UOM">{row.uom}</td>
                      <td label="Category">{row.category}</td>
                      <td label="Qty">
                        {orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" ? (
                          <input
                            className="form-control"
                            type="number"
                            value={row.qty}
                            disabled
                            min="1"
                            onChange={(e) => valueInputChange1(e, index, "qty")}
                            required
                          />
                        ) : (

                          <input
                            className="form-control"
                            type="number"
                            value={row.qty}

                            min="1"
                            onChange={(e) => valueInputChange1(e, index, "qty")}
                            required
                          />
                        )}

                      </td>

                      {orderData.orderStatus == "Shipped" ? (
                        <td label="Qty Delivered">
                          <input
                            className="form-control"
                            type="number"
                            value={row.qtyDelivered}
                            min="1"
                            required
                            disabled
                          />
                        </td>
                      ) : (
                        "")}

                      {orderData.orderStatus == "Shipped" ? (
                        <td label="Fill Rate %">
                          <input
                            className="form-control"
                            type="number"
                            value={(row.qtyDelivered * 100 / row.qty).toFixed(2)}
                            min="1"
                            required
                            disabled
                          />
                        </td>
                      ) : (
                        "")} <td label="Price">{row.price.toFixed(2)}</td>
                      <td label="Amount">
                        <input
                          disabled
                          className="form-control"
                          type="number"
                          value={row.amount.toFixed(2)}
                        />
                      </td>
                      <td label="Action">

                        {orderData.orderStatus !== "Complete" && orderData.orderStatus !== "Published" && orderData.orderStatus !== "Shipped" && orderData.orderStatus !== "Pending for Approval" ? (
                          <button
                            className="btn btn-del btn-outline-danger" style={{ marginTop: "0px" }}
                            onClick={() => handleDeleteRow(index)}
                          >
                            Delete
                          </button>) : (<></>)}

                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {selectCustomerName ? (
              <>
                <label>Total Amount :
                  {totalAmount.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true
                  })}/-</label>

                <br />
                <label>Total Qty : {totalQty} </label>
                <br />
                {creditLimitData.length > 0 && (
                  <p>
                    <label>
                      Approved Credit Limit :
                      {creditLimitData[0].approvedCreditLimit.toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                      })}/-</label>

                    <br />
                    <label>
                      Outstanding Amount (Subject to payment realisation) :
                      {creditLimitData[0].outstandingAmount.toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                      })}/-</label>

                    <br />
                    <label>Available Credit Limit :
                      {creditLimitData[0].avalLimit.toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                      })}/-
                    </label>
                    <br />
                    <label>Payment Term : {creditLimitData[0].paymentTerm.toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true
                    })}/-</label>

                  </p>
                )}
              </>
            ) : (
              "")}
          </div>
          <div className="row col-xs-6 col-md-12 col-sm-12">
            <div className="col-lg-6 col-sm-8 col-md-6 col-xs-8 comments">
              <label htmlFor="orderDate">Comments: </label>

              {orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval" ? (
                ""
              ) : (<input
                className="form-control"
                type="text"
                id="comment"
                value={orderData.comment}
                onChange={(e) =>
                  setOrderData({ ...orderData, comment: e.target.value })
                }
              />
              )}  </div>

            <div>
              {orderId ? (
                // <button onClick={fetchFile}>Open File in New Tab</button>
                <div class="col-lg-6 col-sm-8 col-md-6 col-xs-8">
                  <label htmlFor="UploadedAttachments:">Uploaded Attachments: </label>

                  <AttachmentList orderId={orderId} />
                </div>
              ) : (
                <div className="form-group custom-file">
                  <label htmlFor="fileUpload">Select Files:</label>
                  <input
                    type="file"
                    id="fileUpload"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3">

            {
              aproveButton == true && orderData.orderStatus === "Pending for Approval" && userRole === "ROLE_ADMIN" ?
                (orderData.firstAppEmailId.indexOf(userEmail) !== -1 && orderData.firstAppEmailId === userEmail && orderData.firstAppEmail === true) || (orderData.secondAppEmailId === userEmail && orderData.secondAppEmail === true) ?
                  <div>
                    <button
                      className="btn btn-primary custom-button btn-contai align-bottom mx-1 "
                      disabled
                    >
                      {" "}
                      Approve{" "}
                    </button>
                    {" "}
                    <button
                      className="btn btn-primary custom-button btn-contai align-bottom mx-1 "
                      disabled
                    >
                      {" "}
                      Reject{" "}
                    </button>
                  </div>

                  :
                  <div>
                    <button
                      className="btn btn-primary custom-button btn-contai align-bottom mx-1 "
                      onClick={handleAprove}
                    >
                      {" "}
                      Approve{" "}
                    </button>
                    {" "}
                    <button
                      className="btn btn-primary custom-button btn-contai align-bottom mx-1 "
                      onClick={handleOpen}
                    >
                      {" "}
                      Reject{" "}
                    </button>
                  </div>

                : <></>
            }
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={handleClose}
                >
                  <i><ImCross size={20} /></i>
                </IconButton>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Provide Reject Reason
                </Typography>

                <FormControl
                  fullWidth
                  style={{ margin: "10px 0" }}
                >
                  <InputLabel id="rejectReasonLabel"></InputLabel>
                  <Select
                    labelId="rejectReasonLabel"
                    id="rejectReasonSelect"
                    value={rejectReason}
                    onChange={(e) =>
                      setRejectReason(e.target.value)
                    }
                  >
                    <MenuItem value="Select reason">Select reason</MenuItem>
                    <MenuItem value="Payment issue">
                      Payment issue
                    </MenuItem>
                    <MenuItem value="Hold by operation team">
                      Hold by operation team
                    </MenuItem>
                    <MenuItem value="Low inventory">
                      Low inventory
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-justify",
                    marginTop: "10px",
                  }}
                >  <button
                  onClick={handleReject}
                  className="btn btn-del btn-outline-success"
                >
                    Save
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-del btn-outline-danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </Box>
              </Box>
            </Modal>


            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Provide Reject Reason
                </Typography>

                <FormControl fullWidth style={{ margin: '10px 0' }}>
                  <InputLabel id="rejectReasonLabel">Reject Reason</InputLabel>
                  <Select
                    labelId="rejectReasonLabel"
                    id="rejectReasonSelect"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  >
                    <MenuItem value="paymentIssue">Payment issue</MenuItem>
                    <MenuItem value="holdByOperationTeam">Hold by operation team</MenuItem>
                    <MenuItem value="lowInventory">Low inventory</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'space-justify', marginTop: '10px' }}>
                  <button
                    className="btn btn-del btn-outline-danger"

                    onClick={handleClose}
                  > Cancel </button>

                  <button
                    onClick={handleReject}
                    className="btn btn-del btn-outline-success"
                  >
                    Save
                  </button>
                </Box>
              </Box>
            </Modal> */}

            {orderId ? (
              orderData.orderStatus == "Pending" ? (
                <button

                  className="btn btn-primary custom-button btn-contai align-bottom mx-1 "
                  onClick={handleSubmit}
                >
                  {" "}
                  Update{" "}
                </button>
              ) : (
                <></>
              )
            ) : (
              <button
                className="btn btn-primary custom-button btn-contai align-bottom mx-1"
                onClick={handleSubmit}
              >
                {" "}
                Submit
              </button>
            )}

            {orderData.orderStatus === "Complete" || orderData.orderStatus === "Published" || orderData.orderStatus === "Shipped" || orderData.orderStatus === "Pending for Approval" ? (
              "") : (
              <button
                className="btn btn-primary custom-button btn-contai align-bottom mx-1"
                onClick={handleSaveTemplate}
              >
                Save as Template
              </button>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default CreateOrder;
