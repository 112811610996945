import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@mui/icons-material';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import * as XLSX from "xlsx"; // Import xlsx library

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import containersImage from "../../assets/images/ReportsEmpty.png";

import { API } from "../../services/axiosConfig";
import Navbar from "../../common/TopNavbar";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Typography, Button } from '@mui/material'; 
import { FaFilter } from "react-icons/fa";
import {ImCross} from 'react-icons/im'
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import moment from 'moment/moment';
import swal from 'sweetalert';
const Shortsupplysummaryreport = () => {
  const navigate = useNavigate();
  const dataTableRef = useRef(null); // Ref for the DataTable instance
  const [pdfContent, setPdfContent] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  const generatePdf = () => {
    const dataTable = dataTableRef.current;
  
    if (dataTable) {
      // Get the current search value from DataTable
      const searchValue = dataTable.search();
  
      // Apply the search filter to DataTable
      dataTable.search('').draw(); // Reset the previous search
      dataTable.search(searchValue).draw();
  
      // Get the filtered data
      const filteredData = dataTable.rows({ search: 'applied' }).data().toArray();
  
      if (filteredData.length > 0) {
        const pdf = new jsPDF('l', 'mm', 'a4');
        pdf.text(15, 10, "Short Supply Item wise Report")
       
        const columns = ['Location', 'Item No.','Item Name', 'Item Category','Ordered Quantity', 'Shipped Quantity', 'Short Supply Quantity'];
        const tableData = filteredData.map(item => 
            [
              item.LocationName,
              item.itemno,
              item.itemname,
              item.Category,
              item.orderedquantity,
              item.shippedqty,
              item.shortsupplyqty,
        ]);
       
        pdf.autoTable({ head: [columns], body: tableData });

        var pageCount = pdf.internal.getNumberOfPages(); //Total Page Number
        for(let i = 0; i < pageCount; i++) { 
          pdf.setPage(i); 
          let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber; //Current Page
          pdf.setFontSize(8);
          pdf.text('Page: ' + pageCurrent + ' of ' + pageCount, 10, pdf.internal.pageSize.height - 10);
          pdf.text('Generated DateTime : ' + new Date().toLocaleString() , pdf.internal.pageSize.width - 70 , pdf.internal.pageSize.height - 10);
        }
       
        pdf.save('Shortsupplyitemwisereport.pdf');

      } else {
        alert("No data found for the given search criteria.");
      }
    } else {
      alert("DataTable is not initialized");
    }
  };
  
  const exportToExcel = () => {
    const dataTable = dataTableRef.current;
  
    if (dataTable) {
      const filteredData = dataTable.rows({ search: "applied" }).data().toArray();
  
      if (filteredData.length > 0) {
        // Define the specific mapping for column names
        const columnMapping = [
          "LocationName",
          "itemno",
          "itemname",
          "Category",
          "orderedquantity",
          "shippedqty",
          "shortsupplyqty",
        ];
  
        // Create a header row with specific column names using the mapping
        const header = columnMapping;
  
        // Map the filtered data to match the column names
        const dataWithColumnNames = [
          header,
          ...filteredData.map((item) => [
            item.LocationName,
            item.itemno,
            item.itemname,
            item.Category,
            item.orderedquantity,
            item.shippedqty,
            item.shortsupplyqty,
          ]),
        ];
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithColumnNames);
  
        // Save the workbook as an Excel file
        XLSX.utils.book_append_sheet(workbook, worksheet, "Shortsupplyitemwisereport");
        XLSX.writeFile(workbook, "Shortsupplyitemwisereport.xlsx");
      } else {
        alert("No data found for the given search criteria.");
      }
    } else {
      alert("DataTable is not initialized");
    }
  };
  
  const initializeDataTable = (data) => {
    const dataTable = $('#dataTable').DataTable({
        "sDom": 'Lfrtlip'    ,
        "scrollX": true,
        data: data,
        columns: [
         
          { data: 'LocationName', title: 'Location' },
          { data: 'itemno', title: 'Item No.' },
          { data: 'itemname', title: 'Item Name',name:'itemname' },
          { data: 'Category', title: 'Item Category' ,name:'itemcategory'},
          { data: 'orderedquantity', title: 'Ordered Quantity' ,className: 'dt-right'},
          { data: 'shippedqty', title: 'Shipped Quantity',className: 'dt-right' },
          { data: 'shortsupplyqty', title: 'Short Supply Quantity' ,className: 'dt-right'}
        ],
        language: {
          emptyTable: "No records found for the given search",
        },
      });
    
      // Destroy the DataTable instance if it already exists
      if (dataTableRef.current) {
        dataTableRef.current.destroy();
      }
    
      // Update the ref with the new DataTable instance
      dataTableRef.current = dataTable;
      console.log("DataTable Rows:", dataTable.rows().data().toArray());
    };
  
  const fetchData = async () => {
    try {
   
      const response = await fetch(`${API.defaults.baseURL}shortsupplysummaryreport/` + sessionStorage.getItem('userEmail'));
     if (!response.ok) {
        alert('Orders Not Found');

        navigate('/Dashboard', { replace: true });
        return;
      }
  
      const data = await response.json();
      console.log('Fetched Data:', data);
  
      let filteredData = data;
  
      if (startDate && endDate) {
        filteredData = data.filter((item) => {
          const parsedOrderDate = moment(item.OrderDate, "DD-MM-YYYY");
          const isInRange = parsedOrderDate.isBetween(
            startDate,
            endDate,
            null,
            "[inclusive]"
          );
          return isInRange;
        });
      }
      
  
      if (filteredData.length > 0) {
        if (!dataTableRef.current) {
          // Initialize DataTable only if not already initialized
          initializeDataTable(filteredData);

        } else {

          // Update DataTable with new data
          const dataTable = dataTableRef.current;
          dataTable.clear().rows.add(filteredData).draw();
        }
      } else {
        console.warn('No data available to initialize or update DataTable.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      swal("Error", error?.message,"error") 
    }
  };
  
  const applyFilter = () => {
    fetchData();

  };
  
  const resetFilter = () => {
    setStartDate(null);
    setEndDate(null);
  
    const dataTable = dataTableRef.current;
  
    if (dataTable) {
      dataTable.search("").columns().search("").draw(); // Clear DataTable search and column filters
    }
  };
  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  return (
    <div>
      {/* <h4 className="h4-heading" style={{ fontWeight: '400', color: 'black', marginLeft: '10px', fontSize: '30px' }}>
  Short Supply Summary Report
</h4> */}
<Navbar/>
<div className="forheaderdev">
  {/* <img  src={containersImage} alt="" style={{ maxHeight: "100%", height:"20vh", width: "100%", objectFit: "fill"}}></img> */}
  <div className="headerdivtitle"><h5><b style={{color: "white"}}>Short Supply Item wise Report</b></h5></div>
  
</div>
<Link
          to="/reportslink"
          className="btn custom-button btn-contai align-bottom mx-1"
          style={{
            top: "0px",
            position: "absolute",
            marginTop: "0px;",
            alignSelf: "left",
            color:"white"
          }}
        >
          <ArrowLeft /> Back
        </Link>
<div className="d-flex col-md-12 col-sm-12" style={{marginTop:"20px"}}>
      <div className="d-flex col-md-6 col-sm-12">
      <div style={{ marginBottom: "10px",marginRight:"3px",marginLeft:"10px" }} className="">
        {/* <label htmlFor="startDate" style={{marginRight:"5px"}}>Start Date :</label> */}
      <label htmlFor="endDate" style={{marginRight:"10px",marginTop:"10px",marginLeft:"none"}} > Filter By Orderdate</label>
        <DatePicker
          id="startDate"
          className="form-control"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Select start date"
          dateFormat="dd/MM/yyyy" 
        />
      </div>
      <label htmlFor="endDate" style={{marginRight:"10px",marginTop:"10px",marginLeft:"none"}} >To</label>

      <div style={{ marginBottom: "10px",marginRight:"10px"  }}>
        {/* <label htmlFor="endDate" style={{marginRight:"5px"}} >End Date :</label> */}
        <DatePicker
          id="endDate"
          className="form-control"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="Select end date"
          dateFormat="dd/MM/yyyy" 
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Button variant="contained" className="custom-button-filter-tat" style={{backgroundColor:"#312267",padding:'8px',marginRight:'10px'}} title="Apply Filter" onClick={applyFilter}>
          
          <FaFilter size={20}/>
        </Button>
        <Button variant="contained"  className="btn btn-sm" style={{backgroundColor:"#312267",color:"000000"}} onClick={resetFilter}>
          {/* X */}
          <i><ImCross size={20}/></i>
        </Button>
      </div>
      </div>
      <div className="d-flex col-md-6 col-sm-12 justify-content-end">
      <div style={{ marginBottom: "10px" }} className="d-flex">
        <label htmlFor="storeFilter">Filter by Item Name:</label>
        <input
          type="text"
          id="storeFilter"
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;
            const dataTable = dataTableRef.current;

            if (dataTable) {
              dataTable.column("itemname:name").search(value).draw();
            }
          }}
        />
      </div>
      <div style={{ marginBottom: "10px" }} className="d-flex">
        <label htmlFor="asmFilter">Filter by Item Category:</label>
        <input
          type="text"
          id="asmFilter"
          className="form-control col-md-1"
          onChange={(e) => {
            const value = e.target.value;
            const dataTable = dataTableRef.current;

            if (dataTable) {
              dataTable.column("itemcategory:name").search(value).draw();
            }
          }}
        />
      </div>
      </div>
      </div>
      <h4
        className="h4-heading col-md-4"
        style={{
          fontWeight: "400",
          color: "black",
          marginLeft: "10px",
          // fontSize: "20px",
        }}
      >
        
        <button
          className="btn align-bottom btn-outline-danger btn-sm tat-down-btn"
          onClick={generatePdf}
          title="Download PDF"
        >
          {/* Download PDF */}
          <i><AiOutlineFilePdf size={20} /></i>
        </button>
        <button className="btn btn-outline-danger btn-sm mt-2 mx-2" style={{ minWidth: '50px' }}  onClick={exportToExcel} title="Export to Excel" >
                        <i><RiFileExcel2Fill size={20} /></i>
                      </button>
      </h4>

      <table  id="dataTable" style={{ width: '100%' }}> 
 <thead>
          <tr>
          <th>Location</th>
          <th>Item No.</th>
          <th>Item Name</th>
          <th>Item Category</th>
          <th>Ordered Quantity</th>
          <th>Shipped Quantity</th>
          <th>Short Supply Quantity</th>
          </tr>
        </thead>
        <tbody>{/* Table body content will be populated dynamically by DataTable */}</tbody>
      </table>
     

      
      
    </div>
  );
};

export default Shortsupplysummaryreport;
