import React from 'react';
import Navbar from '../../common/TopNavbar';
import Content from '../../component/Order/CreateOrder';

const CreateOrderPage = () => {
  return (
    <>
      <div style={{width:"100%",height:"8vh",position:"fixed",top:"0",zIndex:"999",marginTop:"0px !important"}}>
        <Navbar  />
      </div>
      <div style={{position:"relative",top:"8vh"}}>
        <Content />
      </div>
    </>
  )
} 

export default CreateOrderPage