import React from 'react';
import Navbar from '../../common/TopNavbar';
import Sidebar from '../../common/Sidebar';
import Content from '../../component/Dashboard/Dashboard';

const DashboardPage = () => {
  return (
      <div className='ms-body ms-aside-left-open ms-primary-theme'  style={{width:"100%",height:"8vh",position:"fixed",top:"0",zIndex:"999",marginTop:"0px !important",left:"8vh"}}>
        <Sidebar />
        <main className="body-content">
                <Content />
            </main>
            </div>
  )
} 

export default DashboardPage