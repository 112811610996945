import React, { useState } from "react";
import axios from "axios";
import authService from "../../services/auth.service";
// import "../../assets/css/register.css";
import "../../assets/css/login2.css"
// import Company from "../../assets/images/yteduyhkf3.jpg"
import Company from "../../assets/images/Snowman_Logo.png";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom/dist";
import containersImage from "../../assets/images/LandingLogo.png";
import { API } from "../../services/axiosConfig";
import LoadingSpinner from "../../common/LoadingSpinner";

function RegistrationForm() {
  const [username, setUsername] = useState("");
  const [emailConfirmationStatus, setEmailConfirmationStatus] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [role, setRole] = useState(["user"]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();
  const handleNameChange = (event) => {
    setUsername(event.target.value);
    setErrors({ ...errors, username: "" });
  };

  const handleemailChange = (event) => {
    setemail(event.target.value);
    setErrors({ ...errors, email: "" });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrors({ ...errors, password: "" });
  };

  const handleconfirmpasswordChange = (event) => {
    setconfirmpassword(event.target.value);
    setErrors({ ...errors, confirmpassword: "" });
  };
  const validateForm = () => {
    const newErrors = {};

    if (!username) {
      newErrors.username = "Full name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!password) {
      newErrors.password = "Password is required";
    }

    if (!confirmpassword) {
      newErrors.confirmpassword = "Please confirm your password";
    } else if (password !== confirmpassword) {
      newErrors.confirmpassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const reset = () => {
    setPassword("");
    setUsername("");
    setemail("");
    setconfirmpassword("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      if (validateForm()) {
        const response = await authService.register(
          username,
          email,
          password,
          confirmpassword,
          role,
          emailConfirmationStatus
        );
  
        const user = {
          id: response.data,
          username: username,
          email: email,
        };
  
        await API.post("api/auth/sendconfirmmail", user);
  
        swal({
          title: "User registration successfully!",
          text: "Confirmation Email Sent. Please check your email for further instructions.",
          icon: "success",
        }).then((willDelete) => {
          if (willDelete) {
            reset();
            navigate("/", { replace: true });
          }
        });
      } else {
        console.log("Validation failed");
      }
    } catch (error) {
      console.log(error);
      swal("Error", error?.message,"error") 
  
      // Check if the error message is available in the response
      if (error.response && error.response.data && error.response.data.message) {
        // Display the error message from the API response
        swal({
          title: "User registration failed!",
          text: error.response.data.message,
          icon: "error",
        });
      } else {
        // Display a generic error message
        swal({
          title: "User registration failed!",
          text: "An error occurred during registration. Please try again later.",
          icon: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const imageUrl =
    "https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp";

  const sectionStyles = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="row">
        {isLoading == true ? <LoadingSpinner /> : null}

    <div className="row col-12" style={{height: "100vh"}} >
      <div className="col-sm-0 col-md-9 hidden-mobile" style={{height: "100vh"}}>
      <img
                    src={containersImage}
                    style={{ maxHeight: "100%", height:"100vh", width: "100%", objectFit: "cover" }}
                  />
      </div>
      <div className="col-sm-12 col-md-3"  style={{alignItems: "center"}}>



      <div className="right-box-regi mt-2"  style={{alignItems: "center"}}>
          <div className="row align-items-center"  style={{alignItems: "center"}}>
            <div className="header-text mb-1" style={{textAlign: "center"}}>
              {/* <h2>Hello,Again</h2> */}
              <img src={Company} className="img-fluid mb-3" style={{width:"100px"}} />
              <p>Nice to see you again</p>
            </div>
            <form onSubmit={handleSubmit} className="regiForm">
              <div className="input-group mb-3 row">
                <label className="label-email-pass"  style={{paddingLeft:"none !important"}}>Full Name</label>
                <>
                  <input
                      className={`form-control light-gray-input fs-6 ${errors.username ? 'is-invalid' : ''}`}
                    placeholder="Enter Full Name"
                    type="text"
                    value={username}
                    onChange={handleNameChange}
                  />
                 {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                </>
              </div>
              <div className="input-group mb-4 row">
                <label className="label-email-pass">Email id</label>
                <>
                  <input
                    type="text"
                    className={`form-control light-gray-input fs-6 ${errors.email ? 'is-invalid' : ''}`}
                    placeholder="Enter Email id"
                    id="email"
                    value={email}
                    onChange={handleemailChange}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </>
              </div>
              <div className="input-group mb-4 row">
                <label className="label-email-pass">Password</label>
                <>
                  <input
                    type="password"
                    className={`form-control light-gray-input fs-6 ${errors.email ? 'is-invalid' : ''}`}
                    placeholder="Enter Password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </>
              </div>
              <div className="input-group mb-4 row">
                <label className="label-email-pass">Confirm Password</label>
                <>
                  <input
                    type="password"
                    className={`form-control light-gray-input fs-6 ${errors.email ? 'is-invalid' : ''}`}
                    placeholder="Enter confirm password"
                    id="confirmpassword"
                    value={confirmpassword}
                    onChange={handleconfirmpasswordChange}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </>
              </div>
              
              <div className="loginBtn input-group mb-4">
                <button type="submit" className="btn btn-lg btn-primary w-100 fs-6">
                  <strong>Sign up</strong>
                </button>
              </div>
              <hr />
              <div className="row dont-accnt">
              <div className="col text-center">
                <strong  className="d-flex align-items-center justify-content-center">
                Already a Member? <a href="/" className="ml-2" style={{marginLeft:"4px"}}>Sign in now</a>
                </strong>
              </div>
              </div>
            </form>
          </div>
        </div>
 








    </div>
    </div>
    </div>
  );
}

export default RegistrationForm;