import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { API } from '../services/axiosConfig';
import { ArrowLeft } from '@mui/icons-material';
import { ArrowRight } from "@mui/icons-material";
import containersImage from "../assets/images/ReportsEmpty.png";
import Navbar from "../common/TopNavbar";

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import ReactDOM from "react-dom";


// chart temp start
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import swal from 'sweetalert';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// chart temp comp

function ReportsLink() {
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [DataForReport, setDataForReport] = useState([]);
  const [DataForKPI, setDataForKPI] = useState([]);
  const [DataSelected, setDataSelected] = useState([]);
  const [checked, setChecked] = useState([]);

  const [uniqueStoreNames, setUniqueStoreNames] = useState(new Set());
  const [uniqueLocation, setUniqueLocation] = useState(new Set());
  const [orderstoday, setorderstoday] = useState('');
  const [shippedtoday, setshippedtoday] = useState('');
  const [pendingforshipment, setpendingforshipment] = useState('');
  const [fillrate, setfillrate] = useState('');
  const [fillratemtd, setfillratemtd] = useState('');
  const [fillrateytd, setfillrateytd] = useState('');


  const [data1, setData1] = useState({
    labels: [],
    datasets: [
      {
        id: 1,
        label: 'Orders Created',
        data: [],
        backgroundColor: ["#F3BA05"]
      },
      {
        id: 1,
        label: 'Orders Shipped',
        data: [],
        backgroundColor: ["#0D188D"]
      }
    ],
  });

  const navigate = useNavigate();
  useEffect(() => {

    fetchData();
    fetchKPIData();
    const endpoint = 'templates';
    fetch(`${API.defaults.baseURL}${endpoint}`)
      // fetch('http://localhost:5052/templates')
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  const fetchKPIData = async () => {
    try {

      const response = await fetch(
        `${API.defaults.baseURL}dashboardkpi/` +
        sessionStorage.getItem("userEmail")
      );
      if (!response.ok) {
        alert("Orders Not Found");
        navigate("/Dashboard", { replace: true });
        return;
      }

      const dataKPI = await response.json();
      setDataForKPI(dataKPI);
      setorderstoday(dataKPI[0].OrdToday);
      setshippedtoday(dataKPI[0].ShippedToday);
      setpendingforshipment(dataKPI[0].PendingForShipment);
      setfillrate(dataKPI[0].FillRate);
      setfillratemtd(dataKPI[0].FillRateMTD);
      setfillrateytd(dataKPI[0].FillRateYTD);

      console.log("Fetched Data123:", dataKPI);

    }
    catch (error) {
      console.error("Error fetching data:", error);
      swal("Error", error?.message,"error") 
    }
  };


  const fetchData = async () => {
    try {
      const response = await fetch(`${API.defaults.baseURL}graphqty/` + sessionStorage.getItem('userEmail'));
      if (!response.ok) {
        alert('Orders Not Found');
        navigate('/Dashboard', { replace: true });
        return;
      }
      const data = await response.json();
      setDataForReport(data);
      setDataSelected(data);
      // Extract unique store names from the data
      const uniqueStoreNamesSet = new Set(data.map(item => item.customer));

      // Update the state with unique store names
      setUniqueStoreNames(uniqueStoreNamesSet);

      const uniqueLocationSet = new Set(data.map(item => item.location));

      // Update the state with unique store names
      setUniqueLocation(uniqueLocationSet);

      const groupedData = data.reduce((accumulator, current) => {
        const date = formatDate(current.orderdate);
        if (!accumulator[date]) {
          accumulator[date] = {
            created: 0,
            shipped: 0
          };
        }
        accumulator[date].created += current.created;
        accumulator[date].shipped += current.shipped;
        return accumulator;
      }, {});

      // Extract unique dates and sort them
      const uniqueDates = Object.keys(groupedData).sort();

      // Create datasets for the chart
      const datasets = [
        {
          label: 'Orders Created',
          data: [],
          backgroundColor: "#F3BA05"
        },
        {
          label: 'Orders Shipped',
          data: [],
          backgroundColor: "#0D188D"
        }
      ];

      // Fill datasets with aggregated data
      uniqueDates.forEach(date => {
        datasets[0].data.push(groupedData[date].created);
        datasets[1].data.push(groupedData[date].shipped);
      });

      const updatedChartData = {
        labels: uniqueDates,
        datasets: datasets,
      };
      setData1(updatedChartData);


      console.log('Fetched Data Reports Dashboard:', data);
    } catch (error) {
      console.error('Error fetching data:', error);
      swal("Error", error?.message,"error") 
    }
  };

  const renderStoreCheckboxes = () => {
    return Array.from(uniqueStoreNames).map(storeName => (
      <div key={storeName}>
        {storeName && (
          <>
            <input value={storeName} type="checkbox" onChange={handleCheck} />
            <span>  {storeName}</span>
          </>
        )}
      </div>
    ));
  };

  const renderLocationCheckboxes = () => {
    return Array.from(uniqueLocation).map((location) => (
      <div key={location}>
        {location && (
          <>
            <input value={location} type="checkbox" onChange={handleCheck} />
            <span>   {location}</span>
          </>
        )}
      </div>
    ));
  };


  const handleCheck = (event) => {
    const selectedValue = event.target.value;
  
    // Toggle the selected value in the checked list
    const updatedList = checked.includes(selectedValue)
      ? checked.filter(item => item !== selectedValue)
      : [...checked, selectedValue];
  
    // Update the checked values
    setChecked(updatedList);
  
    if (updatedList.length === 0) {
      // Reset the data to its original state
      fetchData();
      return;
    }
  
    // Filter the data based on selected stores and locations
    const updatedData = DataForReport.filter(item => updatedList.includes(item.customer) || updatedList.includes(item.location));
  
    // Group the filtered data by orderdate
    const groupedData = updatedData.reduce((accumulator, current) => {
      const date = formatDate(current.orderdate);
      if (!accumulator[date]) {
        accumulator[date] = {
          created: 0,
          shipped: 0
        };
      }
      accumulator[date].created += current.created;
      accumulator[date].shipped += current.shipped;
      return accumulator;
    }, {});
  
    // Extract unique dates and sort them
    const uniqueDates = Object.keys(groupedData).sort();
  
    // Create datasets for the chart
    const datasets = [
      {
        label: 'Orders Created',
        data: [],
        backgroundColor: "#F3BA05"
      },
      {
        label: 'Orders Shipped',
        data: [],
        backgroundColor: "#0D188D"
      }
    ];
  
    // Fill datasets with aggregated data
    uniqueDates.forEach(date => {
      datasets[0].data.push(groupedData[date].created);
      datasets[1].data.push(groupedData[date].shipped);
    });
  
    // Update chart data
    setData1({
      labels: uniqueDates,
      datasets: datasets,
    });
  };
  

   
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so we add 1.
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";

  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";



  return (
    <div>

      <Navbar />
      <div className="pt-2 pb-2">
        <div className="forheaderdev">

          <div className="headerdivtitle"><h5><b style={{ color: "white" }}>ORDER MANAGEMENT DASHBOARD</b></h5></div>

        </div>
        <Link to="/Dashboard" className="onhoverClose custom-button btn-contai align-bottom mx-1" style={{ top: "10px", position: "absolute", marginTop: "50px;", alignSelf: "left", textDecoration: "none", padding: "8px", color: "#fff" }} >
          <ArrowLeft /> Back
        </Link>
      </div>

      <div>
        <div class="header">
          <div className="row" style={{ marginLeft: "5px" }}>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", cursor: "grab", backgroundColor: "#D1EEFC" }} onClick={() => navigate("/Orderstodayreport")} >
                <div class="card-header"><center>Orders Today</center></div>
                <div class="card-title"><center><h1>{orderstoday}</h1></center> </div>
              </div>

            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", cursor: "grab", backgroundColor: "#EEEEEE" }} onClick={() => navigate("/Shippedtodayreport")} >
                <div class="card-header"><center>Shipped Today</center></div>
                <div class="card-title"><center><h1>{shippedtoday}</h1></center> </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", cursor: "grab", backgroundColor: "#D1EEFC" }} onClick={() => navigate("/Orderstobeshippedtoday")} >
                <div class="card-header"><center>Orders Pending for Shipment</center></div>
                <div class="card-title"><center><h1>{pendingforshipment}</h1></center> </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", backgroundColor: "#EEEEEE" }}>
                <div class="card-header"><center>Fill Rate Today</center></div>
                <div class="card-title"><center><h1>{fillrate}</h1></center> </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", backgroundColor: "#D1EEFC" }}>
                <div class="card-header"><center>Fill Rate MTD</center></div>
                <div class="card-title"><center><h1>{fillratemtd}</h1></center> </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <div class="card" style={{ width: "220px", height: "110px", margin: "1px", backgroundColor: "#EEEEEE" }}>
                <div class="card-header"><center>Fill Rate YTD</center></div>
                <div class="card-title"><center><h1>{fillrateytd}</h1></center> </div>
              </div>
            </div>

          </div>



        </div>

        <div class="row" style={{ display: "table", clear: "both", marginTop: "10px" }}>
          <div class="leftcolumn" style={{ float: "left", width: "210px" }} >
            <br />
            <Link className="button-17" to='/Fillratereport' style={{ textDecoration: "none", width: "200px" }}>
              Fill Rate
            </Link>
            <br />
            <Link className="button-17" to='/Ordersummaryreport' style={{ textDecoration: "none", width: "200px" }}>
              All Order Summary
            </Link>
            <br />
            <Link className="button-17" to='/DetailedOrdersummaryreport' style={{ textDecoration: "none", width: "200px" }}>
              Detailed All Order Summary
            </Link>
            <br />
            <Link className="button-17" to='/uspTATReport' style={{ textDecoration: "none", width: "200px" }}>
              TAT Report
            </Link>
            <br />
            <Link className="button-17" to='/agingreport' style={{ textDecoration: "none", width: "200px" }}>
              Aging Report
            </Link>
            <br />
            <Link className="button-17" to='/shortsupplyreport' style={{ textDecoration: "none", width: "200px" }}>
              Short Supply Report
            </Link>
            <br />
            <Link className="button-17" to='/shortsupplysummaryreport' style={{ textDecoration: "none", width: "200px" }}>
              Short Supply Item wise Report
            </Link>

          </div>
          <div class="leftcolumn" style={{ float: "left", width: "70%" }} >
            <div class="card" style={{ border: "1px solid", padding: "1px", boxShadow: '5px 10px 18px #888888', margin: "1px", height: "100%", width: "100%", textAlign: "center" }}>
              <br />
              <center>


                <Line data={data1} />


              </center>
            </div>

          </div>

          <div class="leftcolumn" style={{ float: "left", width: "10%", verticalAlign: "center" }} >

            <div class="card" style={{ width: "320px", height: "210px", margin: "1px" }}>
              <div class="card-header"><center>Select Store</center></div>
              <div class="card-body" style={{ height: "100%", overflow: "scroll", width: "300px", border: "1px" }}>

                {renderStoreCheckboxes()}
              </div>
            </div>
            <div class="card" style={{ width: "320px", height: "210px", margin: "1px" }} >
              <div class="card-header"><center>Select Location</center></div>
              <div class="card-body" style={{ height: "200px", overflow: "scroll", width: "300px", border: "1px" }}>
                {renderLocationCheckboxes()}
              </div>

            </div>
          </div>

        </div>

        <br />


      </div>

      <div className="login container d-flex justify-content-center  min-vh-100">
        <div className="order-body pt-0">
        </div>
      </div>

    </div>
  );
}

export default ReportsLink;
