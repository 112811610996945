import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Company from "../../assets/images/Snowman_Logo.png";
import swal from "sweetalert";
import authService from "../../services/auth.service";
import "../../assets/css/login2.css";
import containersImage from "../../assets/images/LandingLogo.png";
import LoadingSpinner from "../../common/LoadingSpinner";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const params = new URLSearchParams(window.location.search);
    const OrderID_ForApproval = params.get('OrderID_ForApproval');
    const ApprovalType_ForApproval = params.get('ApprovalType_ForApproval');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !password) {
      setIsLoading(false);
      swal("Validation Error", "Please enter both email and password.", "error");
      return;
    }

    if (!emailRegex.test(email)) {
      setIsLoading(false);
      swal("Validation Error", "Please enter a valid email address.", "error");
      return;
    }

    try {
      const response = await authService.login(email, password);

   

      const userRole = response.roles[0];
      const { email: userEmail, aproval, id, username, groupName, manger } = response;

      if (userRole === "ROLE_ADMIN" || response.emailConfirmationStatus) {
        sessionStorage.setItem("OrderID_ForApproval", OrderID_ForApproval);
        sessionStorage.setItem("ApprovalType_ForApproval", ApprovalType_ForApproval);
        sessionStorage.setItem("userRole", userRole);
        sessionStorage.setItem("userEmail", userEmail);
        sessionStorage.setItem("aproval", aproval);
        sessionStorage.setItem("userId", id);
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("groupName", groupName);
        sessionStorage.setItem("isManager", manger);

        setIsLoading(false);
        await swal("Login!", "Logged in successfully!", "success");

        setTimeout(() => {
          if (OrderID_ForApproval == null) {
            navigate("/Dashboard", { replace: true });
          } else {
            navigate("/order/" + OrderID_ForApproval, { replace: true });
          }
          window.location.reload();
        }, 1000);
      } else {
        setIsLoading(false);
        await swal("Login Failed!", "Confirm your email", "warning");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message.includes("Unauthorized")) {
        swal("Login Failed", "Invalid email or password.", "error");
      } else {
        swal("Error", error.response.data.message, "error");
      }
      console.error("Login failed", error);
    }
  };

  return (
    <div className="row">
      {isLoading === true ? <LoadingSpinner /> : null}

      <div className="row col-12" style={{ height: "100vh" }}>
        <div className="col-sm-0 col-lg-9 hidden-mobile" style={{ height: "100vh" }}>
          <img
            src={containersImage}
            style={{ maxHeight: "100%", height: "100vh", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-sm-12 col-lg-3" style={{ alignItems: "center" }}>
          <div className="right-box" style={{ marginLeft: "50px" }}>
            <div className="row align-items-center">
              <div className="header-text mb-4" style={{ textAlign: "center" }}>
                <img src={Company} className="img-fluid" />
                <p>Nice to see you again</p>
              </div>
              <div className="input-group mb-3 row">
                <label className="label-email-pass label-ml" style={{ paddingLeft: "13px !important" }}>
                  Login
                </label>
                <input
                  className="form-control light-gray-input"
                  placeholder="Enter Email id"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group mb-4 row">
                <label className="label-email-pass">Password</label>
                <input
                  type="password"
                  className="form-control light-gray-input"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <div className="d-flex justify-content-around align-items-between">
                  <div className="form-check remem-me col-md-8">
                    <div className="toggle-switch">
                      <input type="checkbox" className="checkbox" name="label" id="label" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="forgot d-flex align-items-center justify-content-center">
                <small>
                  <a href="/forgotpassword" style={{ marginLeft: "6px" }}>
                    <strong>Forgot Password?</strong>
                  </a>
                </small>
              </div>
              <div className="loginBtn input-group mb-4">
                <button className="btn btn-lg btn-primary w-100 fs-6" onClick={handleLogin}>
                  <strong>Sign in</strong>
                </button>
              </div>
              <hr />
              <div className="row dont-accnt">
                <div className="col text-center">
                  <strong className="d-flex align-items-center justify-content-center">
                    Don't have an account? <a href="/register" style={{ marginLeft: "6px" }}>Sign Up now</a>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
