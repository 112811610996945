import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Homepage from '../../Pages/Order/OrderListPage';
import Login from '../../component/login/Login';
import CreateOrderPage from '../../Pages/Order/CreateOrderPage';
import RegistrationForm from '../../component/registration/RegistrationForm';
import EmailConfirmation from '../registration/EmailConfirmation';
import Dashboard from '../../Pages/dashboard/DashboardPage';
import authService from '../../services/auth.service';
import Template from '../../Pages/Template';
import ForgotPassword from '../login/ForgotPassword';
import Report from '../Order/Report';
import OrderMatrixTable from '../Order/OrderMatrixTable';
import Tatreport from '../Order/Tatreport';
import Agingreport from '../Order/Agingreport';
import Shortsupplyreport from '../Order/Shortsupplyreport';
import ReportsLink from '../../Pages/ReportsLink';
import Shortsupplysummaryreport from '../Order/Shortsupplysummaryreport';
import OrdersTodayReport from '../Order/OrdersTodayReport';
import ShippedTodayReport from '../Order/ShippedTodayReport';
import OrdersToBeShippedTodayReport from '../Order/OrdersToBeShippedTodayReport';
import FillRateReport from '../Order/FillRateReport';
import OrderssummaryReport from '../Order/OrderSummaryReport';
import DetailedOrderSummaryReport from '../Order/DetailedOrderSummaryReport';
import ReportsDashboard from '../../Pages/ReportsDashboard';
import ResetPasswordPage from '../login/ResetPasswordPage';
import swal from 'sweetalert';

function Content() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const userId = sessionStorage.getItem("userId");

  useEffect(() => {
    console.log(userId);
    const fetchUser = async () => {
      try {
        const user = await authService.getCurrentUser();
        setUser(user);
      } catch (error) {
        swal("Error", error.message,"error")
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const PrivateRoute = ({ element, ...rest }) => {
    if (loading) {
      return <div>Loading...</div>; // Add a loading indicator while fetching user data
    }

    return user ? element : <Navigate to="/" />;
  };

  return (
    <div className="h-100 w-100">
      <BrowserRouter>
        <Routes>
          <Route exact path='/resetpasswordpage'  element={<ResetPasswordPage/>}/>
          <Route exact path='/forgotpassword'  element={<ForgotPassword/>}/>
          <Route exact path="/template" element={<PrivateRoute element={<Template />} />} />
          <Route exact path="/orderlist" element={<PrivateRoute element={<Homepage />} />} />
          <Route exact path="/CreateOrder" element={<PrivateRoute element={<CreateOrderPage />} />} />
          <Route exact path="/template/:tempId" element={<PrivateRoute element={<CreateOrderPage />} />} />
          <Route path="/order/:orderId" element={<PrivateRoute element={<CreateOrderPage />} />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/Dashboard"  element={<PrivateRoute element={<Dashboard />}/>} />
          <Route exact path="/EmailConfirmation" element={<EmailConfirmation />} />
          <Route exact path="/register" element={<RegistrationForm />} />
          <Route exact path="/pdfReport" element={<PrivateRoute element={<Report />} />} />
         <Route exact path="/OrderMatrixTable" element={<PrivateRoute element={<OrderMatrixTable />} />} />
         <Route exact path ='/uspTATReport' element ={<PrivateRoute element ={<Tatreport/>}/>}/>
         <Route exact path="/agingreport" element={<PrivateRoute element={<Agingreport />} />} />
         <Route exact path="/shortsupplyreport" element={<PrivateRoute element={<Shortsupplyreport />} />} />
         <Route exact path="/reportslink" element={<PrivateRoute element={<ReportsLink />} />} />
         <Route exact path="/shortsupplysummaryreport" element={<PrivateRoute element={<Shortsupplysummaryreport />} />} />
         <Route exact path="/Orderstodayreport" element={<PrivateRoute element={<OrdersTodayReport />} />} />
         <Route exact path="/Shippedtodayreport" element={<PrivateRoute element={<ShippedTodayReport />} />} />
         <Route exact path="/Orderstobeshippedtoday" element={<PrivateRoute element={<OrdersToBeShippedTodayReport />} />} />
         <Route exact path="/Fillratereport" element={<PrivateRoute element={<FillRateReport />} />} />
         <Route exact path="/Ordersummaryreport" element={<PrivateRoute element={<OrderssummaryReport />} />} />
         <Route exact path="/DetailedOrdersummaryreport" element={<PrivateRoute element={<DetailedOrderSummaryReport />} />} />
         <Route exact path="/reportsdashboard" element={<PrivateRoute element={<ReportsDashboard />} />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Content;
