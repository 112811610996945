import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@mui/icons-material';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import swal from 'sweetalert';


const Report = () => {
  const navigate = useNavigate();
  const dataTableRef = useRef(null); // Ref for the DataTable instance
  const [pdfContent, setPdfContent] = useState('');

  const generatePdf = () => {
    const dataTable = dataTableRef.current;
  
    if (dataTable) {
      // Get the current search value from DataTable
      const searchValue = dataTable.search();
  
      // Apply the search filter to DataTable
      dataTable.search('').draw(); // Reset the previous search
      dataTable.search(searchValue).draw();
  
      // Get the filtered data
      const filteredData = dataTable.rows({ search: 'applied' }).data().toArray();
  
      if (filteredData.length > 0) {
        const pdf = new jsPDF();
        const columns = ['Order Date', 'Location', 'SrNo', 'Customer', 'Order Number', 'Username'];
        const tableData = filteredData.map(item => [
          item.orderdate,
          item.location,
          item.SrNo,
          item.customer,
          item.ordernumber,
          item.username
        ]);
  
        pdf.autoTable({ head: [columns], body: tableData });
        pdf.save('report.pdf');
      } else {
        alert("No data found for the given search criteria.");
      }
    } else {
      alert("DataTable is not initialized");
    }
  };
  
  
  

  const fetchData = async () => {
    try {
      const response = await fetch(`http://localhost:5052/tatreport/abcd`);
      if (!response.ok) {
        alert('Orders Not Found');
        navigate('/Dashboard', { replace: true });
        return;
      }
      const data = await response.json();
      console.log('Fetched Data:', data); // Log the fetched data
      setPdfContent(JSON.stringify(data));
  
      // Check if DataTable is already initialized
      if (!dataTableRef.current) {
        initializeDataTable(data); // Initialize DataTable after fetching data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      swal("Error", error?.message,"error") 
    }
  };
  

  const initializeDataTable = (data) => {
    console.log('Initializing DataTable with Data:', data);
    const dataTable = $('#dataTable').DataTable({
      data: data,
      columns: [
        { data: 'orderdate', title: 'Order Date' },
        { data: 'location', title: 'Location' },
        { data: 'SrNo', title: 'SrNo' },
        { data: 'customer', title: 'Customer' },
        { data: 'ordernumber', title: 'Order Number' },
        { data: 'username', title: 'Username' },
      ],
    });

    // Update the ref with the DataTable instance
    dataTableRef.current = dataTable;
    console.log('DataTable Rows:', dataTable.rows().data().toArray());
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  return (
    <div>
      <h4 className="h4-heading" style={{ fontWeight: '400', color: 'black', marginLeft: '10px', fontSize: '30px' }}>
        <Link to="/Dashboard" style={{ textDecoration: 'none' }}>
          <button className="btn btn-primary custom-button btn-contai align-bottom mx-1">
            <ArrowLeft /> Back
          </button>
        </Link>
      </h4>

      <button className="btn btn-success" onClick={generatePdf}>
        Download PDF
      </button>
      <table id="dataTable" className="table table-striped table-bordered" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Order Date</th>
            <th>Location</th>
            <th>SrNo</th>
            <th>Customer</th>
            <th>Order Number</th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>{/* Table body content will be populated dynamically by DataTable */}</tbody>
      </table>
    </div>
  );
};

export default Report;
