import axios from "axios";
import { API } from "./axiosConfig";
import swal from "sweetalert";

const API_URL = "api/auth/";

class AuthService {
  login(email, password) {
    return API
      .post(API_URL + "signin", {
        email,
        password
      })
      .then(response => {
        console.log(response.data)
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      })
      .catch((error)=>{
        if (error.response && error.response.status === 401) {
          swal("Login Failed", "Invalid email or password.", "error");
        } else {
          swal("Error", error.message, "error");
        }
        throw error; // rethrow the error after handling it
      
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password,confirmpassword,role,emailConfirmationStatus) {
    return API.post(API_URL + "signup", {
      emailConfirmationStatus,
      username,
      email,
      password,
      confirmpassword,
      role
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
