import React, { useState, useEffect } from "react";
import { API } from "../services/axiosConfig";
import swal from "sweetalert";

const AttachmentList=({orderId})=> {
  const [attachments, setAttachments] = useState([]);
  
  useEffect(() => {
    // Fetch attachments when the component mounts
    fetchAttachments();
  }, []);

  const fetchAttachments = () => {
    // Make a GET request to your API endpoint
    const endpoint = "uploadatt";
    fetch(`${API.defaults.baseURL}${endpoint}/`+orderId)
    // fetch("uploadatt/"+orderId)
      .then((response) => response.json())
      .catch((error)=>{
        swal("Error", error.message,"error")
      })
      .then((data) => {
        setAttachments(data);
      })
      .catch((error) => {
        swal("Error", error.message,"error")
        console.error("Error fetching attachments:", error);
      });
  };

  return (
    <div>
      
      <ul>
        {attachments.map((attachment) => (
          <li key={attachment.id}>
            <a
              href={`${API}/attachment/${attachment.orderId}/${encodeURIComponent(attachment.fileName)}`}
              target="_blank" // Open link in a new tab
              rel="noopener noreferrer" // Recommended for security
            >
              {attachment.fileName}
            </a>
          </li>
        ))}
      </ul>
      {/* <button onClick={fetchAttachments}>Fetch Attachments</button> */}
    </div>
  );
}

export default AttachmentList;
