import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { API } from '../services/axiosConfig';
import { ArrowLeft } from '@mui/icons-material';
import Navbar from "../common/TopNavbar";
import swal from 'sweetalert';

function DropdownComponent() {
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
         
    const endpoint = 'api/templates/template-names'; 
    fetch(`${API.defaults.baseURL}${endpoint}`)
    // fetch('http://localhost:5052/templates')
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => {
        swal("Error", error.message,"error")
         console.error('Error fetching data:', error)});
  }, []);

  const handleDropdownChange = event => {
    setSelectedOption(event.target.value);
    // navigate("/template"+id, { replace: true });
  };
 const renderDropdownItems = () => {
  return data.map((templateName, index) => (
    <Dropdown.Item key={index} onClick={() => handleDropdownChange(templateName)}>
      {templateName}
    </Dropdown.Item>
  ));
};
  return (
    <div>

<Navbar/>
    <div className="pt-2 pb-2">
    <div className="forheaderdev">

  <div className="headerdivtitle"><h5><b style={{color: "white"}}>TEMPLATE</b></h5></div>
  
</div>
      <Link to= "/Dashboard" className="onhoverClose custom-button btn-contai align-bottom mx-1" style={{top: "10px",position: "absolute",  marginTop: "50px;", alignSelf: "left",textDecoration:"none",padding:"8px",color:"#fff"}} >
        <ArrowLeft /> Back
      </Link>
 {/* <div class="container-list">
            <b>  <h4 className="h4-heading" style={{ fontWeight: "400", color: "black", marginLeft: "10px", fontSize: "30px" }}>
             Reports </h4></b>
          </div> */}

        </div>  
      
    <div className="pt-2 pb-2">



        </div>
    <div className="login container d-flex justify-content-center  min-vh-100">
    <div className="order-body pt-0">
    <DropdownButton id="dropdown-basic-button" style={{width: "300px"}} title="Select Template">
      {data.length === 0 ? (
        <Dropdown.Item disabled>Data not found</Dropdown.Item>
      ) : (
        data.map(item => (
          <Dropdown.Item key={item.templateId} href={`/template/${item.templateId}`}>
            {item.templateName}
          </Dropdown.Item>
        ))
      )}
    </DropdownButton>
</div>
        </div>
   
    </div>
  );
}

export default DropdownComponent;
