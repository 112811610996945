import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "@mui/icons-material";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import containersImage from "../../assets/images/ReportsEmpty.png";
import { API } from "../../services/axiosConfig";
import Navbar from "../../common/TopNavbar";
import * as XLSX from "xlsx"; // Import xlsx library
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import swal from "sweetalert";
const ShippedTodayReport = () => {
  const navigate = useNavigate();
  const dataTableRef = useRef(null); // Ref for the DataTable instance
  const [pdfContent, setPdfContent] = useState("");

  const generatePdf = () => {
    const dataTable = dataTableRef.current;

    if (dataTable) {
      // Get the current search value from DataTable
      const searchValue = dataTable.search();

      // Apply the search filter to DataTable
      dataTable.search("").draw(); // Reset the previous search
      dataTable.search(searchValue).draw();

      // Get the filtered data
      const filteredData = dataTable
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (filteredData.length > 0) {
        const pdf = new jsPDF("l", "mm", "a4");
        pdf.text(20, 10, "Shipped Today Report");
      
        const columns = [
          "Location",
          "Order No.",
          "Sales Order No.",
          "Order Date",
          "Customer",
          "Store City",
          "Shipment Date",
          "Order Quantity",
          "Delivered Quantity",
          "Order Value",
          "Fill %",
        ];
        const tableData = filteredData.map((item) => [
          item.LocationName,
          item.OrderNumber,
          item.SoNumber,
          item.OrderDate,
          item.Customer,
          item.City,
          item.ShipmentDate,
          item.Quantity,
          item.QuantityDelivered,
          item.Amount,
          item.FillPercentage,
        ]);
       
        pdf.autoTable({ head: [columns], body: tableData });

        var pageCount = pdf.internal.getNumberOfPages(); //Total Page Number
        for (let i = 0; i < pageCount; i++) {
          pdf.setPage(i);
          let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber; //Current Page
          pdf.setFontSize(8);
          pdf.text(
            "Page: " + pageCurrent + " of " + pageCount,
            10,
            pdf.internal.pageSize.height - 10
          );
          pdf.text(
            "Generated DateTime : " + new Date().toLocaleString(),
            pdf.internal.pageSize.width - 70,
            pdf.internal.pageSize.height - 10
          );
        }

        pdf.save("ShippedtodayReport.pdf");
      } else {
        alert("No data found for the given search criteria.");
      }
    } else {
      alert("DataTable is not initialized");
    }
  };
  
  const exportToExcel = () => {
    const dataTable = dataTableRef.current;
  
    if (dataTable) {
      const filteredData = dataTable.rows({ search: "applied" }).data().toArray();
  
      if (filteredData.length > 0) {
        // Define the specific mapping for column names
        const columnMapping = [
          "LocationName",
          "OrderNumber",
          "SoNumber",
          "OrderDate",
          "Customer",
          "City",
          "ShipmentDate",
          "Quantity",
          "QuantityDelivered",
          "Amount",
          "FillPercentage",
        ];
  
        // Create a header row with specific column names using the mapping
        const header = columnMapping;
  
        // Map the filtered data to match the column names
        const dataWithColumnNames = [
          header,
          ...filteredData.map((item) => [
            item.LocationName,
            item.OrderNumber,
            item.SoNumber,
            item.OrderDate,
            item.Customer,
            item.City,
            item.ShipmentDate,
            item.Quantity,
            item.QuantityDelivered,
            item.Amount,
            item.FillPercentage,
          ]),
        ];
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithColumnNames);
  
        // Save the workbook as an Excel file
        XLSX.utils.book_append_sheet(workbook, worksheet, "ShippedtodayReport");
        XLSX.writeFile(workbook, "ShippedtodayReport.xlsx");
      } else {
        alert("No data found for the given search criteria.");
      }
    } else {
      alert("DataTable is not initialized");
    }
  };
  
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API.defaults.baseURL}commonreportshippedtoday/` +
          sessionStorage.getItem("userEmail")
      );
      if (!response.ok) {
        alert("Orders Not Found");
        navigate("/Dashboard", { replace: true });
        return;
      }
      const data = await response.json();
      console.log("Fetched Data:", data); // Log the fetched data
      setPdfContent(JSON.stringify(data));

      // Check if DataTable is already initialized
      if (!dataTableRef.current) {
        initializeDataTable(data); // Initialize DataTable after fetching data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      swal("Error", error?.message,"error") 
    }
  };

  const initializeDataTable = (data) => {
    console.log("Initializing DataTable with Data:", data);
    const dataTable = $("#dataTable").DataTable({
      sDom: "Lfrtlip",
      pagingType: "simple_numbers",
      "scrollX": true,
      data: data,
      columns: [
        { data: "LocationName", title: "Location" },
        { data: "OrderNumber", title: "Order No." },
        { data: "SoNumber", title: "Sales Order No." },
        { data: "OrderDate", title: "Order Date" },
        { data: "Customer", title: "Customer", name: "customerNo" },
        { data: "City", title: "Store City", name: "store" },
        { data: "ShipmentDate", title: "Shipment Date" },
        { data: "Quantity", title: "Order Quantity" ,className: 'dt-right'},
        { data: "QuantityDelivered", title: "Delivered Quantity",className: 'dt-right' },
        { data: "Amount", title: "Order Value" ,className: 'dt-right'  },
        { data: "FillPercentage", title: "Fill %" ,className: 'dt-right'},
      ],
    
      language: {
        emptyTable: "No records found for the given search",
      },
    });
  
    // Destroy the DataTable instance if it already exists
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
    }
  
    // Update the ref with the new DataTable instance
    dataTableRef.current = dataTable;
    console.log("DataTable Rows:", dataTable.rows().data().toArray());
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  return (
    <div>
      {/* <h4 className="h4-heading" style={{ fontWeight: '400', color: 'black', marginLeft: '10px', fontSize: '30px' }}>
  TAT Report
</h4> */}
      {/* <img
                src={containersImage}
                style={{ maxHeight: "20%", height:"20vh", width: "100%", objectFit: "fill", marginBottom: "10px" }}
              /> */}
      <Navbar />
      <div className="forheaderdev">
        {/* <img  src={containersImage} alt="" style={{ maxHeight: "100%", height:"20vh", width: "100%", objectFit: "fill"}}></img> */}
        <div className="headerdivtitle">
          <h5>
            <b style={{color: "white"}}>Shipped Today</b>
          </h5>
        </div>
      </div>
      <Link
          to="/reportslink"
          className="btn custom-button btn-contai align-bottom mx-1"
          style={{
            top: "0px",
            position: "absolute",
            marginTop: "0px;",
            alignSelf: "left",
            color:"white"
          }}
        >
          <ArrowLeft /> Back
        </Link>
      <div className="d-flex col-md-12 col-sm-12 justify-content-start" style={{margin:"30px 0 0 0"}}>
      <div style={{ marginBottom: "10px",whiteSpace: "nowrap" }} className="d-flex">
        <label htmlFor="storeFilter">Filter by Store City : </label>
        <input
          type="text"
          id="storeFilter"
          style={{ width: "100%",margin:"0 10px" }}
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;
            const dataTable = dataTableRef.current;

            if (dataTable) {
              dataTable.column("store:name").search(value).draw();
            }
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" ,whiteSpace: "nowrap"}} className="d-flex">
        <label htmlFor="asmFilter">Filter by Customer :</label>
        <input
          type="text"
          className="form-control"
          id="asmFilter"
          style={{ width: "100%",margin:"0 10px" }}
          onChange={(e) => {
            const value = e.target.value;
            const dataTable = dataTableRef.current;

            if (dataTable) {
              dataTable.column("customerNo:name").search(value).draw();
            }
          }}
        />
      </div>
      </div>
      <h4
        className="h4-heading"
        style={{
          fontWeight: "400",
          color: "black",
          marginLeft: "10px",
          fontSize: "30px",
        }}
      >
       
        <button
          className="btn align-bottom btn-outline-danger btn-sm tat-down-btn"
          onClick={generatePdf}
          title="Download PDF"

        >
          {/* Download PDF */}
          <i><AiOutlineFilePdf size={20} /></i>
        </button>
        <button className="btn btn-outline-danger btn-sm mt-2 mx-2" style={{ minWidth: '50px' }}  onClick={exportToExcel} title="Export to Excel" >
              <i><RiFileExcel2Fill size={20} /></i>
                      </button>
      </h4>
      <table  id="dataTable" style={{ width: '100%' }}> 

        <thead>
          <tr>
            <th>Location</th>
            <th>Order No.</th>
            <th>Sales Order No.</th>
            <th>Order Date</th>
            <th>Customer</th>
            <th>Store City</th>
            <th>Shipment Date</th>
            <th>Order Quantity</th>
            <th>Delivered Quantity</th>
            <th className="dt-cneter">Order Value</th>
            <th>Fill %</th>
          </tr>
        </thead>
        <tbody>
          {/* Table body content will be populated dynamically by DataTable */}
        </tbody>
      </table>

    
    </div>
  );
};

export default ShippedTodayReport;
