import React from 'react'
import './spinner.css'
import { Backdrop} from '@mui/material';

export default function LoadingSpinner() {
  return (
<div>
    <Backdrop className="css-1ji7omv-MuiBackdrop-root"
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>
                <div id="loading-wrapper">
       <div class="spinner">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div>Loading...</div>
</div>
    </div> </Backdrop>
    </div>
  )
}
